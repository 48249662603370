<template>
  <!-- ======= About Section ======= -->
  <section id="about" class="about">
      <div class="container" data-aos="fade-up">
        <div class="row gx-0">
          <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
            <img :src="resource + aboutus.photo" class="img-fluid" alt="">
          </div>
          <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
            <div class="content">
              <h3>Who We Are</h3>
              <h2>{{ aboutus.name_en }}</h2>
              <div v-html="aboutus.description_en"></div>
              <!-- <div class="text-center text-lg-start">
                <a :href="aboutus.link" target="_blank" class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                  <span>Read More</span>
                  <i class="bi bi-arrow-right"></i>
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section><!-- End About Section -->
</template>

<script>
import {resource} from '@/networks/domain'
export default {
  props : {
    aboutus : {
      type : [Object, Array],
      default : []
    }
  },
  data(){
    return {
      resource : resource
    }
  }
}
</script>

<style>

</style>