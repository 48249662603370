
export {
    getNews,
    sideBar,
    newDetail
}


const getNews = `/article`;
const newDetail = '/article/detail';
const sideBar = '/article/sidebar';