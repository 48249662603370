<template>
    <main id="main">
        <section id="blog" class="blog" style="margin-top:100px;">
            <div class="container" data-aos="fade-up">
                <div class="row">
                    <div class="col-12 shadow rounded-4 p-5">
                        <h2 class="fw-bold text-center txt-system m-0">Register Successfully</h2>
                        <p class="my-5" style="text-align: justify;">
                            You have successfully verified your membership account. 
                            <br><br> Please login. <br>
                            As soon as you have successfully logged in, head over to the Membership Profile Tab, scroll down to the Documents area, and upload the necessary files. 
                            <br> 
                            Please note the requirement documents as following:
                            <br>
                            <ul>
                                <li>Current Certificate of Registration with Ministry of Commerce (Scanned Copy)</li>
                                <li>Patent Tax Certificate (Scanned Copy)</li>
                                <li>Name and contact of CEO and additional representative</li>
                                <li>Company LOGO</li>
                            </ul>
                        </p>
                        <div class="text-center">
                            <router-link class="btn btn-outline-primary" to="/login"><h2 class="m-0">Click here to login</h2></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    </template>
    
    <script>
    import axios from 'axios';
    import { sideBar } from '@/networks/NewsPage/news.service'
    import { resource } from '@/networks/domain';
    
    export default {
        // props : ["data"],
        data(){
            return {
                articles : [],
                videos : [],
                resource : resource,
                isTrue : true
            }
        },
        methods : {
            checkProps(){
                if(this.$route.params.data != "success"){
                    if(this.$router.back.length > 0){
                        this.$router.go(-1)
                    } else {
                        this.$router.push('/');
                    }
                }
            }
        },
        mounted(){
            // this.checkProps();
        }
    }
    </script>
    
    <style>
    
    </style>
    