import kh from '@/languages/kh.json'
import en from '@/languages/en.json'
import { createI18n } from 'vue-i18n/index.mjs'



const language = createI18n({
    locale : localStorage.language || 'en',
    fallbackFormat : true,
    fallbackLocale : "en",
    messages: {
        en : en,
        kh : kh
    }
})

export default language