
export {
    homePage,
    getContact,
    message,
}


const homePage = `/home`;
const getContact = '/contact';
const message = '/contact/message';