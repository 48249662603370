<template>
    <div id="edit" class="modal">
      <!-- Modal content -->
      <div class="modal-content">
          <div class="d-flex justify-content-between align-items-center">
              <h2 class="fw-bolder">Add Document</h2>
              <i class="bi bi-x-circle close" @click="closeModal()"></i>
          </div>
          <form action="" @submit.prevent="submit()">
              <div class="row">
                  <div class="col-lg-6 col-md-12">
                      <div class="row">
                          <div class="col-lg-12 col-md-12">
                              <div class="mb-3">
                                  <label for="edescription" class="form-label">Description</label>
                                  <input v-model="edata.description"  type="text" class="form-control" id="edescription" placeholder="Description">
                              </div>
                          </div>
                          <div class="col-lg-12 col-md-12">
                              <div class="mb-3">
                                  <label for="edocumentType" class="form-label">Select Type</label>
                                  <Select2 
                                      id="edocumentType"
                                      v-model="edata.type_id" 
                                      :options="documentTypes" 
                                      @change="myChangeEvent($event)"
                                      @select="mySelectEvent($event)"
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                      <div class="mb-3">
                          <label for="photo" class="form-label">Photo</label>
                          <input id="photo" @change="changeImg('photo',$event)" type="file" class="form-control">
                          <button type="button" @click="resetImg('photo')" v-if="pho" title="Reset Photo" class="btn btn-outline-dark float-end mt-3"><i class="bi bi-arrow-clockwise"></i></button>
                          <img src="" class="my-2 w-25 rounded" id="imgEdit"/>
                      </div>
                  </div>
  
                  <div class="col-12 text-end border-top pt-3 mt-1">
                      <button :disabled="isAdd" type="submit" class="btn btn-outline-primary">
                          <span v-if="!isAdd">Update</span>
                          <span v-else>Updating ...</span>
                      </button>
                  </div>
              </div>
          </form>
      </div>
  </div>
  </template>
  
  <script>
  import Select2 from '@/components/select2/Select2View.vue';
  import { saveDocument } from '@/networks/Admin/memberProfile.service'
  import axios from 'axios';
  import { resource } from '@/networks/domain';
   
  export default {
      props : {
          documentTypes : {
              type : [Object, Array],
              default : []
          },
          edata : {
                type : [Object],
                default : {}
          }
      },
      components : {
          Select2
      },
      data(){
          return {
              documentTypeId : '',
              photo : '',
              pho: false,
              phoo: null,
              description : '',
              isAdd : false,
              resource : resource
          }
      },
      methods : {
          myChangeEvent(e){
              // console.log(e);
          },
          mySelectEvent(e){
              // console.log(e);
          },
          closeModal(){
              // When the user clicks on <span> (x), close the modal
              $('#edit').toggleClass('d-block');
              this.clearData();
          },
          clearData(){
              this.documentTypeId = '',
              this.photo = '',
              this.pho= false,
              this.phoo= null,
              this.description = '',
              this.isAdd = false
              $('#photo').val('');
          },
          resetImg(value){
              if(value == "photo"){
                  this.photo = '';
                  this.pho = false;
                  this.phoo = null;
                  $('#photo').val('');
                  $('#imgEdit').attr("src",`${resource + this.edata.photo}`);
              }
          },
          changeImg(value,obj){
              if(value == "photo"){
                  this.photo = URL.createObjectURL(obj.target.files[0]);
                  this.phoo = obj.target.files[0];
                  this.pho = true;
                  $('#imgEdit').attr("src",`${this.photo}`);
              }
          },
          async submit(){
              this.isAdd = true;
              var data = new FormData();
              data.append('photo',this.phoo);
              data.append('type_id', this.edata.type_id);
              data.append('member_id', this.$store.state.auth.member_id);
              data.append('description',this.edata.description);
              data.append('id', this.edata.id);

  
              await axios.post(saveDocument, data)
              .then((res) => {
                  if(res.data.status == 200){
                      this.$swal(
                          {
                              position: 'top-center',
                              icon: 'success',
                              title: res.data.sms,
                              showConfirmButton: false,
                              timer: 1200
                          }
                      );
                      this.closeModal();
                      this.$emit('UpdateSuccess');
                  } else {
                      this.$swal(
                          {
                              icon: 'error',
                              title : `${res.data.sms}`
                          }
                      );
                  }
              })
              .catch((err)=>{
                // this.$swal(
                //     {
                //         icon: 'warning',
                //         title: err,
                //     }
                // )
              })
              this.isAdd = false;
          }
      }
  }
  </script>
  
  <style>
   #edocumentType ~ .select2{
      width: 100% !important;
   }
  </style>