<template>
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">

<div class="container" data-aos="fade-up">

  <header class="section-header">
    <h2>Contact</h2>
    <p>Contact Us</p>
  </header>

  <div class="row gy-4">

    <div class="col-lg-6">

      <div class="row gy-4">
        <div class="col-md-6">
          <div class="info-box">
            <i class="bi bi-geo-alt"></i>
            <h3>Address</h3>
            <p>{{ contacts.address }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="info-box">
            <i class="bi bi-telephone"></i>
            <h3>Call Us</h3>
            <p>{{ contacts.phone_1 }}<br>{{ contacts.phone_2 }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="info-box">
            <i class="bi bi-envelope"></i>
            <h3>Email Us</h3>
            <p>{{ contacts.email }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="info-box">
            <i class="bi bi-clock"></i>
            <h3>Open Hours</h3>
            <p>{{ contacts.work_day }}<br>{{ contacts.work_hour }}</p>
          </div>
        </div>
      </div>

    </div>

    <div class="col-lg-6">
      <form method="post" class="php-email-form" @submit.prevent="submitForm()">
        <div class="row gy-4">

          <div class="col-md-6">
            <input type="text" v-model="name" name="name" class="form-control" placeholder="Your Name" required>
          </div>

          <div class="col-md-6 ">
            <input type="email" v-model="email" class="form-control" name="email" placeholder="Your Email" required>
          </div>

          <div class="col-md-12">
            <input type="text" v-model="subject" class="form-control" name="subject" placeholder="Subject" required>
          </div>

          <div class="col-md-12">
            <textarea class="form-control" v-model="description" name="message" rows="6" placeholder="Message" required></textarea>
          </div>

          <div class="col-md-12 text-center">
            <div class="loading">Loading</div>
            <div class="error-message"></div>
            <div class="sent-message">Your message has been sent. Thank you!</div>

            <div class="w-100 d-flex flex-column justify-content-center align-items-center mb-2">
              <VueRecaptcha 
                sitekey="6LdsrRMkAAAAAIVFdPwys-viHJSW6320rpmpcInw"
                @verify="verifyMethod"
                @expired="expiredMethod"
              />
              <i class="text-danger mt-2">{{ sms }}</i>
            </div>

            <button :disabled="isSending" type="submit" class="mt-2">
              <span v-if="!isSending">Send Message</span>
              <span v-else>Sending ...</span>
            </button>
          </div>

        </div>
      </form>

    </div>

  </div>

</div>

</section><!-- End Contact Section -->
</template>

<script>
import {resource} from '@/networks/domain'
import axios from 'axios';
import { message } from '@/networks/HomePage/routing.service'
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  props : {
    contacts : {
      type : [Object, Array],
      default : []
    }
  },
  components:{
    VueRecaptcha
  },
  data(){
    return {
      resource : resource,
      name : '',
      email : '',
      subject : '',
      description : '',
      isSending : false,
      res : false,
      sms : ''
    }
  },
  methods : {
    verifyMethod(response){
      if(response){
        this.res = true;
        this.sms = '';
      }
    },
    expiredMethod(){
      this.res = false;
    },
    async submitForm(){
      var data = {
        name : this.name,
        email : this.email,
        subject : this.subject,
        description : this.description 
      }
      if(this.res){
        this.isSending = true;
        await axios.post(message, data)
        .then((res)=>{
          if(res.data.status == 200){
            this.$swal(
                {
                    icon: 'success',
                    title : `${res.data.sms}`
                }
            );
            this.name = '';
            this.email = '';
            this.subject = '';
            this.description = '';
          } else {
            this.$swal(
              {
                icon: 'error',
                title : `${res.data.sms}`
              }
            );
          }
        })
        .catch((err)=>{
          console.log(err);
        })
        this.isSending = false;
      } else {
        this.sms = "This field is required!"
      }
    }
  }
}
</script>

<style>

</style>