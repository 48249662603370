<template>
  <div class="col-lg-8 entries pb-4">
    <div class="rounded shadow">
        <h2 class="fw-bold entry-title p-4">{{ name }}</h2>
        <div v-if="photo != 'null'" class="w-100 mt-4">
            <img :src="resource + photo" alt="" class="w-100">
        </div>
        <div class="entry-content p-4">
            <div class="text-justify" v-html="description"></div>
        </div>
    </div>
  </div>
  </template>
  
  <script>
  import { resource } from '@/networks/domain';
  export default {
      name : 'OrganizationChartView',
      data(){
          return {
              resource : resource
          }
      },
      computed : {
          description(){
              var data = this.$CryptoJS.AES.decrypt(`${this.$route.params.data}`, "Secret Passphrase").toString(this.$CryptoJS.enc.Utf8)
              const description =  data.split("-----");
              return description[1];
          },
          photo(){
              var data = this.$CryptoJS.AES.decrypt(`${this.$route.params.data}`, "Secret Passphrase").toString(this.$CryptoJS.enc.Utf8)
              const photo =  data.split("-----");
              return photo[0];
          },
          name(){
              var data = this.$CryptoJS.AES.decrypt(`${this.$route.params.data}`, "Secret Passphrase").toString(this.$CryptoJS.enc.Utf8)
              const name =  data.split("-----");
              return name[2];
          }
      },
  }
  </script>
  
  <style>
  
  </style>