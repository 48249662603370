<template>
  <main id="main" style="margin-top:100px;">
    <section class="blog">
        <div id="btnGroup" class="container" data-aos="fade-up">
            <div class="row" v-if="$store.state.auth.isApproved == 1">
                <div class="col text-center">
                    <div class="btn-group m-auto" role="group" aria-label="Basic outlined example">
                        <router-link to="/admin/company" :class="`btn ${$route.path == '/admin/company' ? 'btn-primary' : 'btn-outline-primary'}`">Company Directories</router-link>
                        <router-link to="/admin/document" :class="`btn ${$route.path == '/admin/document' ? 'btn-primary' : 'btn-outline-primary'}`">Useful Document</router-link>
                        <router-link to="/admin/membership-profile" :class="`btn ${$route.path == '/admin/membership-profile' ? 'btn-primary' : 'btn-outline-primary'}`">Membership Profile</router-link>
                    </div>
                </div>
            </div>
            <router-view />
        </div>
    </section>
</main>
<div id="#tele"></div>
</template>

<script>

export default {
    created(){
        if(!localStorage.token){
            this.$router.push('/login');
        }
    },
}
</script>

<style>

</style>