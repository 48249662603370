<template>
    <div id="contactPerson" class="modal">
      <!-- Modal content -->
      <div class="modal-content">
          <div class="d-flex justify-content-between align-items-center">
              <h2 class="fw-bolder">Edit Contact Person</h2>
              <i class="bi bi-x-circle close" @click="closeModal()"></i>
          </div>
          <form action="" @submit.prevent="submit()">
            <div class="row">
                <div class="col-lg-4 col-md-12 my-1">
                    <label for="ekeyPersonName" class="form-group">Key Person Name</label>
                    <input v-model="company.key_person_name" type="text" id="ekeyPersonName" class="form-control">
                </div>
                <div class="col-lg-4 col-md-12 my-1">
                    <label for="ekeyPersonPosition" class="form-group">Key Person Position</label>
                    <input v-model="company.key_person_position" type="text" id="ekeyPersonPosition" class="form-control">
                </div>
                <div class="col-lg-4 col-md-12 my-1">
                    <label for="eKeyPersonPhone" class="form-group">Key Person Phone</label>
                    <input v-model="company.key_person_phone" type="text" id="eKeyPersonPhone" class="form-control">
                </div>
                <div class="col-lg-4 col-md-12 my-1">
                    <label for="eKeyPersonEmail" class="form-group">Key Person Email</label>
                    <input v-model="company.key_person_email" type="text" id="eKeyPersonEmail" class="form-control">
                </div>
                <div class="col-12 my-2 text-end">
                    <button :disabled="isAdd" type="submit" class="btn btn-outline-primary">
                        <span v-if="!isAdd">Update</span>
                        <span v-else>Updating ...</span>
                    </button>
                </div>
            </div>
          </form>
      </div>
  </div>
  </template>
  
  <script>
  import Select2 from '@/components/select2/Select2View.vue';
  import { updateBriefandLookingFor } from '@/networks/Admin/memberProfile.service'
  import axios from 'axios';
  import { resource } from '@/networks/domain';
   
  export default {
      components : {
          Select2
      },
      props : {
        company : {
            type : [Object, Array],
            default : {}
        }
      },
      data(){
          return {
              documentTypeId : '',
              photo : '',
              pho: false,
              phoo: null,
              description : '',
              isAdd : false,
              resource : resource
          }
      },
      methods : {
          myChangeEvent(e){
              // console.log(e);
          },
          mySelectEvent(e){
              // console.log(e);
          },
          closeModal(){
              // When the user clicks on <span> (x), close the modal
              $('#contactPerson').toggleClass('d-block');
              this.clearData();
          },
          clearData(){
              this.documentTypeId = '',
              this.photo = '',
              this.pho= false,
              this.phoo= null,
              this.description = '',
              this.isAdd = false
              $('#photo').val('');
          },
          resetImg(value){
              if(value == "photo"){
                  this.photo = '';
                  this.pho = false;
                  this.phoo = null;
                  $('#photo').val('');
                  $('#imgEdit').attr("src",`${resource + this.edata.photo}`);
              }
          },
          changeImg(value,obj){
              if(value == "photo"){
                  this.photo = URL.createObjectURL(obj.target.files[0]);
                  this.phoo = obj.target.files[0];
                  this.pho = true;
                  $('#imgEdit').attr("src",`${this.photo}`);
              }
          },
          async submit(){
              this.isAdd = true;
              var data = {
                member_id : this.$store.state.auth.member_id,
                key_person_name : this.company.key_person_name,
                key_person_position : this.company.key_person_position,
                key_person_phone : this.company.key_person_phone,
                key_person_email : this.company.key_person_email
              };
  
              await axios.post(updateBriefandLookingFor, data)
              .then((res) => {
                  if(res.data.status == 200){
                      this.$swal(
                          {
                              position: 'top-center',
                              icon: 'success',
                              title: res.data.sms,
                              showConfirmButton: false,
                              timer: 1200
                          }
                      );
                      this.closeModal();
                      this.$emit('updateContactPerson',this.company);
                  } else {
                      this.$swal(
                          {
                              icon: 'error',
                              title : `${res.data.sms}`
                          }
                      );
                  }
              })
              .catch((err)=>{
                // this.$swal(
                //     {
                //         icon: 'warning',
                //         title: err,
                //     }
                // )
              })
              this.isAdd = false;
          }
      }
  }
  </script>
  
  <style>
   #edocumentType ~ .select2{
      width: 100% !important;
   }
  </style>