// localhost
// export const domain = "http://127.0.0.1:8000/api/v1";
// export const resource = "https://sr-storage.sgp1.cdn.digitaloceanspaces.com/";

// អត់ប្រើ / នៅខាងចុងទេ 
export const domain = "https://manage.cbchubs.com/api/v1";
export const resource = "https://nexttech-storage.sgp1.cdn.digitaloceanspaces.com/cbc_website/";



//សម្រាប់ប្រើតាមហាងកាហ្វេ
// export const domain = "https://admin.staging.cbchubs.com/api/v1";
// export const resource = "https://nexttech-storage.sgp1.cdn.digitaloceanspaces.com/cbc_website/";