import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import language from "./languages/lang.js";
import axios from "axios";
import { domain } from "./networks/domain";
import VueCryptojs from "vue-cryptojs/src/index.mjs";
import VueSweetalert2 from "vue-sweetalert2";
import Select2 from "@/components/select2/Select2View";

import "sweetalert2/dist/sweetalert2.min.css";
import "@/assets/vendor/aos/aos.css";
import "@/assets/vendor/bootstrap/css/bootstrap.css";
import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "@/assets/vendor/glightbox/css/glightbox.min.css";
import "@/assets/vendor/remixicon/remixicon.css";
import "@/assets/vendor/swiper/swiper-bundle.min.css";
import "@/assets/css/dataTable.css";
import "@/assets/css/modal.css";
import "@/assets/css/style.css";


//set value to vuex
if (localStorage.token) {
  store.commit("id", localStorage.id);
  store.commit("username", localStorage.username);
  store.commit("email", localStorage.email);
  store.commit("language", localStorage.language);
  store.commit("member_id", localStorage.member_id);
  store.commit("role_id", localStorage.role_id);
  store.commit("photo", localStorage.photo);
  store.commit("phone", localStorage.phone);
  store.commit("token", "Bearer " + localStorage.token);
}

axios.defaults.baseURL = domain;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Methods"] = "POST,GET,HEAD,OPTIONS,PUT,DELETE";
axios.defaults.headers.common["xsrfCookieName"] = "XSRF-TOKEN";
axios.defaults.headers.common["xsrfHeaderName"] = "X-XSRF-TOKEN";
axios.defaults.headers.common["Content-Type"] = "multipart/form-data, application/json";
axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;
axios.defaults.headers.common["Access-Control-Allow-Headers"] = "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Authorization";
axios.defaults.headers.common["Authorization"] = store.state.auth.token;


const app = createApp(App);

app
  .component("Select2", Select2)
  .use(VueCryptojs)
  .use(VueSweetalert2, {
    confirmButtonColor: "#4154f1",
  })
  .use(store)
  .use(router)
  .use(language)
  .mount("#app");
