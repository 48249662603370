<template>
<div class="col-lg-8 entries">
    <div v-if="isLoading" class="container position-relative p-0 mb-5">
        <div class="carousel-inner animated-background position-relative rounded">
            <div class="carousel-item btn-divide-left active">
                <div class="carousel-caption d-none d-md-block"></div>
            </div>
        </div>
    </div>
    <div v-else class="row">
        <div class="col">
            <div class="container" data-aos="fade-up" v-if="boards.length > 0">

                <header class="section-header">
                    <!-- <h2>BOARD</h2> -->
                    <p>Board of directors</p>
                </header>

                <div class="row gy-4">

                    <div v-for="(data, index) in boards" :key="data.id" class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" :data-aos-delay="(index+1) * 100">
                        <div class="member shadow rounded">
                            <div class="member-img">
                                <img :src="resource + data.photo" class="img-fluid rounded-top" alt="">
                                <!-- <div class="social">
                                    <a href=""><i class="bi bi-email"></i> {{ data.email }}</a>
                                    <a href=""><i class="bi bi-phone"></i> {{ data.phone }}</a>
                                    <a href=""><i class="bi bi-instagram"></i></a>
                                    <a href=""><i class="bi bi-linkedin"></i></a>
                                </div> -->
                            </div>
                            <div class="member-info p-2">
                                <h4 class="fw-bold text-center mb-0">{{ data.full_name }}</h4>
                                <p class="text-center"><i>{{ data.position }}</i></p>
                                <p style="text-align:justify">{{ data.description }}</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import {
    boardPage
} from '@/networks/BoardPage/board.service'
import {
    resource
} from "@/networks/domain";

export default {
    name: "BoardView",
    data() {
        return {
            resource: resource,
            boards: [],
            isLoading: true
        }
    },
    methods: {
        async getData() {
            await axios
                .get(boardPage)
                .then((res) => {
                    if (res.status == 200) {
                        this.boards = res.data.data.boards;
                        this.isLoading = false;
                    }
                })
                .then(() => {
                    $("html, body").animate({
                            scrollTop: $("#main").offset().top - 100,
                        },
                        20
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    async created() {
        await this.getData();
    }
}
</script>

<style>

</style>
