<template>
  <div class="col-lg-8 entries">
    <div class="entry rounded">
        <div class="mb-5">
            <h2 class="fw-bolder mb-2">Login</h2>
        </div>
        <form
            @submit.prevent="login()"
        >
            <div class="mb-3">
                <label for="email" class="form-label fw-bold">Email <span class="text-danger">*</span></label>
                <input v-model="email" type="text" class="form-control" id="email" required>
            </div>
            <div class="mb-3">
                <label for="password" class="form-label fw-bold">Password <span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                    <input v-model="password" :type="`${isShowEye ? 'text' : 'password'}`" class="form-control" id="password" required>
                    <button type="button" @click="clickEye()" class="input-group-text btn btn-outline-primary"><i :class="`bi ${isShowEye ? 'bi-eye' : 'bi-eye-slash'}`"></i> </button>
                </div>
            </div>
            <div class="mt-3 text-end">
                <button type="submit" :disabled="isLoading" class=" btn btn-outline-primary">
                    <span v-if="isLoading">Processing ...</span>
                    <span v-else>Login</span>
                </button>
            </div>
            <div class="mt-3">
                <router-link to="/login/forget-password" class="text-decoration-underline txt-system">Lost your password?</router-link>
            </div>
            <div class="mt-3">
                <router-link to="/register" class="text-decoration-underline txt-system">Not a member yet? Register now.</router-link>
            </div>
        </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { login } from '@/networks/Auth/login.service'
import { resource } from '@/networks/domain';

export default {
    data(){
        return {
            isLoading : false,
            email : '',
            password : '',
            isShowEye: false,
        }
    },
    created(){
        if(localStorage.token){
            this.$router.push('/admin/company');
        }
    },
    methods : {
        clickEye(){
            this.isShowEye = !this.isShowEye;
        },
        async login(){
            this.isLoading = true;
            await axios.post(login,{
                email : this.email,
                password : this.password 
            }).then((res)=>{
                if(res.data.status == 200){
                    this.$swal(
                        {
                            position: 'top-center',
                            icon: 'success',
                            title: res.data.sms,
                            showConfirmButton: false,
                            timer: 1200
                        }
                    );
                    
                    //set value
                    this.$store.commit('id', res.data.data.id);
                    this.$store.commit('username', res.data.data.username);
                    this.$store.commit('email', res.data.data.email);
                    this.$store.commit('language', res.data.data.language);
                    this.$store.commit('member_id', res.data.data.member_id);
                    this.$store.commit('role_id', res.data.data.role_id);
                    this.$store.commit('photo', res.data.data.photo);
                    this.$store.commit('phone', res.data.data.phone);
                    this.$store.commit('token', 'Bearer ' + res.data.token);

                    localStorage.id = this.$store.state.auth.id;
                    localStorage.username = this.$store.state.auth.username;
                    localStorage.email = this.$store.state.auth.email;
                    localStorage.language = this.$store.state.auth.language;
                    localStorage.member_id = this.$store.state.auth.member_id;
                    localStorage.role_id = this.$store.state.auth.role_id;
                    localStorage.photo = this.$store.state.auth.photo;
                    localStorage.token = res.data.token;

                    axios.defaults.headers.common["Authorization"] = this.$store.state.auth.token;


                    //clear input
                    this.email = '';
                    this.password = '';
                    this.isLoading = false;

                    this.$router.push('/admin/company');
                } else {
                    this.$swal(
                        {
                            icon: 'error',
                            title : `${res.data.sms}`
                        }
                    );
                    this.isLoading = false;
                }
            }).catch((err)=>{
                this.$swal(
                    {
                        icon: 'warning',
                        title: err.message,
                    }
                )
                // console.log(err);
                this.isLoading = false;
            })
        }
    },
    mounted(){
        $('html, body').animate({
            scrollTop: $("#main").offset().top - 100
        }, 20);
    }
}
</script>

<style>

</style>