<template>
<div class="col-lg-8 entries pb-4">
    <div class="rounded shadow">
        <h2 class="fw-bold entry-title px-4 pt-4">{{ name }}</h2>
        <div v-if="photo != 'null'" class="w-100 mt-4">
            <img :src="resource + photo" alt="" class="w-100">
        </div>
        <div v-if="description" class="entry-content p-4">
            <div class="text-justify" v-html="description"></div>
        </div>
        <div class="row">
            <div class="col-12 text-center py-5">
                <router-link to="/register" class="btn btn-outline-primary">Register Now</router-link>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { resource } from '@/networks/domain';
export default {
    data(){
        return {
            resource : resource
        }
    },
    computed : {
        description(){
            var data = this.$CryptoJS.AES.decrypt(`${this.$route.params.data}`, "Secret Passphrase").toString(this.$CryptoJS.enc.Utf8)
            const description =  data.split("-----");
            return description[0];
        },
        photo(){
            var data = this.$CryptoJS.AES.decrypt(`${this.$route.params.data}`, "Secret Passphrase").toString(this.$CryptoJS.enc.Utf8)
            const photo =  data.split("-----");
            return photo[1];
        },
        name(){
            var data = this.$CryptoJS.AES.decrypt(`${this.$route.params.data}`, "Secret Passphrase").toString(this.$CryptoJS.enc.Utf8)
            const name =  data.split("-----");
            return name[2];
        }
    },
    mounted(){
        // console.log(this.description);
    }
}
</script>

<style>

</style>