<template>
    <div class="row my-5">
        <div class="col-lg-4 col-md-12">
            <h2 class="fw-bolder">Company Directories</h2>
        </div>
        <div class="col-lg-2 col-md-12 my-2 my-lg-0">
            <Select2 
                id="groupType"
                class="w-100 p-0"
                v-model="groupType" 
                :options="business_group" 
                @change="myChangeEvent($event)"
                @select="mySelectEvent($event)"
                placeholder="Group Type"
                required
            />
        </div>
        <div class="col-lg-2 col-md-12 my-2 my-lg-0">
            <Select2 
                id="country"
                class="w-100 p-0"
                v-model="country" 
                :options="countries" 
                @change="myChangeEvent($event)"
                @select="mySelectEvent($event)"
                placeholder="Country"
                required
            />
        </div>
        <div class="col-lg-4 col-md-12 my-2 my-lg-0">
            <div class="sidebar-item search-form">
                <form action="">
                    <div class="input-group mb-3">
                        <input v-model="search" type="text" class="form-control" placeholder="Type to find a member" required>
                        <button @click="searchData()" type="button" class="input-group-text btn btn-outline-primary"><i class="bi bi-search"></i> </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row my-5" v-if="!isLoading">
        <div class="col-lg-3 col-md-6 col-sm-12" v-for="i in 4" :key="i">
            <div class="card" aria-hidden="true">
                <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect></svg>
                <div class="card-body">
                    <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                    </h5>
                    <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                    <span class="placeholder col-8"></span>
                    <span class="placeholder col-7"></span>

                    </p>
                    <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-6"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="row my-5" v-else>
        <template v-if="members.length > 0">
            <div class="col-lg-3 col-md-6 col-sm-12 mb-5" v-for="member in members" :key="member.id">
                <div class="m-auto shadow rounded position-relative" style="width:99%;">
                    <div class="col-12 position-relative text-center p-0">
                        <picture>
                            <img v-if="member.logo == '' || member.logo == null" src="@/assets/noCompany.jpeg" class="img-fluid img-thumbnail rounded-0 rounded-top border-0 border-bottom p-0" alt="...">
                            <img v-else :src="resource + member.logo" class="img-fluid img-thumbnail rounded-0 rounded-top border-0 border-bottom p-0" alt="...">
                        </picture>
                        <span class="position-absolute bottom-0 m-2 end-0 px-4 py-1 bg-white shadow rounded">
                            <small>Since: {{ new Date(member.created_at).toLocaleDateString("en-AU", { day : 'numeric', 'month' : 'numeric' , 'year' : 'numeric'}) }}</small>
                        </span>
                    </div>
                    <div class="col-12 p-3" style="font-size: 15px">
                        <h3 class="my-1">{{ member.name }}</h3>
                        <p class="my-1"> ID : {{ member.code }} </p>
                        <p class="my-1"><i>Country : {{ member.country }}</i></p>
                        <p class="my-1">Tel: {{ member.phone }}</p>
                        <p class="my-1">Email : {{ member.email }}</p>
                        <p class="my-1">Web: <a href="#" target="_blank"> {{ member.website }}</a></p>
                        <p class="my-1">Type : {{ member.business_group_name }}</p>
                    </div>
                    <div class="col-12 p-3 text-start">
                        <router-link :to="{ name : 'admin-member-detail', params :{ id : $CryptoJS.AES.encrypt(`${member.id}`, 'Secret Passphrase').toString() } }" class="btn btn-primary">View Detail</router-link>
                    </div>
                </div>
            </div>
        </template>
        <div v-else class="col-12 text-center px-2" style="height : 80px;">
            <div class="shadow rounded-4 w-100 h-100" style="padding-top:30px">
                No Data!
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <p v-if="totalPage == 0 || totalPage == current_page" class="text-center txt-system"><i class="fa-solid fa-ban"></i></p>
            <button 
                v-else
                :disabled="isFetchingMore"
                class="btn btn-outline-primary"
                type="button"
                @click="nextPage(current_page+1)"
            >
                Load More
                <img v-if="isFetchingMore" src="@/assets/sniper.jpeg" alt="" width="12">
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { companyList } from '@/networks/Admin/company.service';
import { resource } from '@/networks/domain';
export default {
    name : 'CompanyView',
    data(){
        return {
            members : [],
            business_group : [{id : '0', text : 'All Group Type'}],
            countries : [{id : '0', text : 'All Countries'}],
            per_page: 12,
            current_page:1,
            totalPage: 0,
            resource : resource,
            isLoading : false,
            isFetchingMore : false,
            search : '',
            groupType : '',
            country : ''
        }
    },
    watch : {
        async groupType(after, before){
            if(after != before){
                this.isLoading = false;
                this.current_page = 1;
                await this.getData(true);
            }
        },
        async country(after, before){
            if(after != before){
                this.isLoading = false;
                this.current_page = 1;
                await this.getData(true);
            }
        }
    },
    methods: {
        myChangeEvent(obj){
            
        },
        mySelectEvent(obj){

        },
        async searchData(){
            this.isLoading = false;
            this.current_page = 1;
            await this.getData(true);
        },
        async getData(search = false){
            let bzId = this.groupType == 0 ? '' : this.groupType;
            await axios.get(`${companyList}?per_page=${this.per_page}&page=${this.current_page}&search=${this.search}&member_id=${this.$store.state.auth.member_id}&country_id=${this.country}&business_group_id=${bzId}`)
            .then((res) => {
                if(res.data.status == 200){
                    this.$store.commit('isApproved', res.data.data.is_approve);
                    if(res.data.data.is_approve != 1){
                        this.$router.push("/admin/membership-profile");
                    } else {
                        if(search == true){
                            this.members = [];
                            this.business_group = [{id : '0', text : 'All Group Type'}];
                            this.countries = [{id : '0', text : 'All Countries'}];
                        }
                        const data = res.data.data.members.data;
                        data.forEach(item => {
                            this.members.push(item);
                        });
                        this.totalPage = res.data.data.members.last_page;

                        var business_groups = res.data.data.business_group;
                        business_groups.forEach(element => {
                            console.log(this.business_group);
                            this.business_group.push({id : element.id, text : element.name});
                        });

                        var countries = res.data.data.countries;
                        countries.forEach(element => {
                            this.countries.push({id : element.id, text : element.name_en})
                        })
                    }
                }
            })
            .catch((err)=>{
                console.log(err);
                // this.$swal(
                //     {
                //         icon: 'warning',
                //         title: err,
                //     }
                // )
            })
            this.isLoading = true;
            this.isFetchingMore = false;
        },
        async nextPage(value){
            this.current_page = value;
            this.isFetchingMore = true;
            await this.getData();
        }
    },
    async created(){
        await this.getData().then(()=>{
            $('html, body').animate({
                scrollTop: $("#main").offset().top - 100
            }, 20);
        });
    },
    mounted(){
        
    }
}
</script>

<style>
#groupType ~ .select2-container--default .select2-selection--single,
#country ~ .select2-container--default .select2-selection--single {
    border: 1px solid #4154f1 !important;
    /* border-bottom: 1px solid #4154f1 !important; */
    /* border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; */
}
#groupType ~ .select2,
#country ~ .select2 {
    width: 100% !important;
}
</style>