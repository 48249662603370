<template>
  <div class="col-lg-8 entries">
    <div v-if="isLoading" class="container position-relative p-0 mb-5">
      <div class="carousel-inner animated-background position-relative rounded">
        <div class="carousel-item btn-divide-left active">
          <div class="carousel-caption d-none d-md-block"></div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div v-for="item in data" :key="item.id" class="col-sm-4">
        <div class="entry rounded-bottom">
          <div class="entry-img rounded-top">
            <img :src="resource + item.thumbnail" alt="" class="img-fluid" />
          </div>

          <h2 class="entry-title">
            <router-link
              style="font-size: 15px"
              :to="{
                name: 'news-detail',
                params: {
                  id: $CryptoJS.AES.encrypt(
                    `${item.id}`,
                    'Secret Passphrase'
                  ).toString(),
                },
              }"
            >
              {{ item.title_en }}
            </router-link>
          </h2>

          <div class="entry-meta">
            <ul>
              <li class="d-flex align-items-center"
              style="font-size: 8px"

              >
                <i class="bi bi-eye"></i> {{ item.viewer }}
              </li>
              <li class="d-flex align-items-center"
              style="font-size: 8px"

              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-calendar-event"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"
                  />
                  <path
                    d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                  />
                </svg>
                &nbsp;
                {{
                  new Date(item.created_at).toLocaleDateString("en-AU", {
                    weekday: "long",
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })
                }}
              </li>
            </ul>
          </div>

          <div class="entry-content">
            <!-- <div class="" v-html="item.description_en"></div> -->
            <div>
              {{ shortDescription(item.description_en, 150) }}...
            </div>
            <div class="read-more mt-4">
              <router-link
                :to="{
                  name: 'news-detail',
                  params: {
                    id: $CryptoJS.AES.encrypt(
                      `${item.id}`,
                      'Secret Passphrase'
                    ).toString(),
                  },
                }"
                >Read More</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blog-pagination overflow-scroll">
      <ul v-if="totalPage > 1" class="justify-content-center">
        <li class="hover-none" v-for="(i,index) in totalPage" :key="index">
          <button 
            :disabled="isFetch"
            type="button"
            :class="`btn ${
              i == current_page ? 'btn-primary' : 'btn-outline-primary'
            }`"
            @click="goToPage(i)"
          >
            {{ i }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getNews } from "@/networks/NewsPage/news.service";
import { resource } from "@/networks/domain";
export default {
  name: "NewsView",
  data() {
    return {
      resource : resource,
      data : [],
      per_page: 6,
      current_page:1,
      totalPage: 0,
      isFetch: false,
      isLoading: true,
    };
  },
  watch: {
    async current_page(after, before) {
      if (after != before) {
        this.isFetch = true;
        this.isLoading = true;
        await this.getData().then(() => {
          this.$nextTick(() => {
            $("html, body").animate(
              {
                scrollTop: $("#main").offset().top - 100,
              },
              20
            );
          });
        });
      }
    },
  },
  methods: {
    goToPage(i) {
      this.current_page = i;
    },
    async getData() {
      await axios
        .get(`${getNews}?per_page=${this.per_page}&page=${this.current_page}`)
        .then((res) => {
          if (res.status == 200) {
            this.data = res.data.data.articles.data;
            this.totalPage = res.data.data.articles.last_page;
            this.isFetch = false;
            this.isLoading = false;
          }
        })
        .then(() => {
          $("html, body").animate(
            {
              scrollTop: $("#main").offset().top - 100,
            },
            20
          );
        })
        .catch((err) => {
          console.log(err);
          // this.$swal(
          //   {
          //     icon: 'warning',
          //     title: err,
          //   }
          // )
        });
    },
    strippedHtml(data) {
      let regex = /(<([^>]+)>)/gi;
      return data.replace(regex, "");
    },
    shortDescription(str, lengthRequired = 100) {
      let str_data = this.strippedHtml(str);
      return str_data.substring(0, lengthRequired);
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style scoped>
.hover-none:hover {
  background-color: transparent !important;
}
</style>
