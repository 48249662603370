import { createStore } from 'vuex'

export default createStore({
  state: {
    app: {
      name: 'Next Tech Cambodia',
      phone: '16 855002',
      api_key: '$2y$10$VB87rm1lo/Sw7HbNa5ydAeBM2i.vDTw2b8xojuDuv1NJg1VrLIDOe',
      type : 'core_system',
      appId : '4e123a77-b9f8-4695-b07a-03151c5802b6'
    },
    auth:{
      id : 0,
      username : '',
      email: '',
      language: '',
      member_id: '',
      role_id: '',
      photo: '',
      phone: '',
      token: '',
      isApproved : 1,
    }
  },
  getters: {
  },
  mutations: {
    id(state,id){
      state.auth.id = id;
    },
    username(state,username){
      state.auth.username = username;
    },
    email(state,email){
      state.auth.email = email;
    },
    language(state,language){
      state.auth.language = language;
    },
    member_id(state,member_id){
      state.auth.member_id = member_id;
    },
    role_id(state,role_id){
      state.auth.role_id = role_id;
    },
    photo(state,photo){
      state.auth.photo = photo;
    },
    phone(state,phone){
      state.auth.phone = phone;
    },
    token(state,token){
      state.auth.token = token;
    },
    isApproved(state,isApproved){
      state.auth.isApproved = isApproved;
    }
},
  actions: {
  },
  modules: {
  }
})
