<template>
    <div class="row my-5">
        <div class="col-lg-8 col-md-12">
            <h2 class="fw-bolder">Document Directories</h2>
        </div>
        <div class="col-lg-4 col-md-12">
            <div class="sidebar-item search-form">
                <div class="input-group mb-3">
                    <input v-model="search" type="text" class="form-control" placeholder="Type to find a member" required>
                    <button @click="searchData()" type="button" class="input-group-text btn btn-outline-primary"><i class="bi bi-search"></i> </button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isLoading" class="row my-5">
        <div class="col-12 rounded-4 shadow p-3">
            <span class="placeholder col-7"></span>
            <span class="placeholder col-4"></span>
            <span class="placeholder col-4"></span>
            <span class="placeholder col-6"></span>
            <span class="placeholder col-8"></span>
            <span class="placeholder col-6"></span>
        </div> 
    </div>
    <template v-else>
        <div class="row my-5" v-if="documents.length > 0">
            <div class="col-12 my-3" v-for="document in documents" :key="document.id">
                <div class="row shadow rounded-4 p-3 m-0">
                    <div class="col-lg-10 col-md-12">
                        <p class="m-0 my-1"><span class="fw-bold">Title</span> : {{ document.title }}</p>
                        <p class="m-0 my-1"><span class="fw-bold">Type</span> : {{ document.type_name }}</p>
                        <p class="m-0 my-1"><span class="fw-bold">Effective Date :</span> <i>{{ new Date(document.effective_date).toLocaleDateString("en-AU", { day : 'numeric', 'month' : 'numeric' , 'year' : 'numeric'}) }}</i></p>
                    </div>
                    <div class="col-lg-2 col-md-12 text-end m-auto pt-lg-0 pt-2">
                        <a :href="resource + document.file_path" target="_blank" class="btn btn-outline-primary">View / Download</a>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row my-5">
            <div class="col-12 text-center px-2" style="height : 80px;">
                <div class="shadow rounded-4 w-100 h-100" style="padding-top:30px">
                    No Data!
                </div>
            </div>
        </div>
    </template>
    <div class="row">
        <div class="col-12 text-center">
            <p v-if="totalPage == 0 || totalPage == current_page" class="text-center txt-system"><i class="fa-solid fa-ban"></i></p>
            <button 
                v-else
                :disabled="isFetchingMore"
                class="btn btn-outline-primary"
                type="button"
                @click="nextPage(current_page+1)"
            >
                Load More
                <img v-if="isFetchingMore" src="@/assets/sniper.jpeg" alt="" width="12">
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { resource } from '@/networks/domain';
import { documentList } from '@/networks/Admin/document.service';
export default {
    name : 'DocumentView',
    data(){
        return{
            documents : [],
            types : [],
            per_page: 10,
            current_page:1,
            totalPage: 0,
            resource : resource,
            isLoading : false,
            isFetchingMore : false,
            search : ''
        }
    },
    methods : {
        async searchData(){
            this.isLoading = true;
            this.current_page = 1;
            await this.getData(true);
        },
        async getData(search = false){
            await axios.get(`${documentList}?per_page=${this.per_page}&page=${this.current_page}&search=${this.search}&member_id=${this.$store.state.auth.member_id}`)
            .then((res)=>{
                if(res.data.status == 200){
                    this.$store.commit('isApproved', res.data.data.is_approve);
                    if(res.data.data.is_approve != 1){
                        this.$router.push("/admin/membership-profile");
                    } else {
                        if(search == true){
                            this.documents = [];
                        }
                        const data = res.data.data.documents.data;
                        data.forEach(item => {
                            this.documents.push(item);
                        });
                        this.totalPage = res.data.data.documents.last_page;
                        this.types = res.data.data.types;
                    }
                }
            })
            .catch((err)=>{
                console.log(err);
                // this.$swal(
                //     {
                //     icon: 'warning',
                //     title: err,
                //     }
                // )
            })
            this.isLoading = false;
            this.isFetchingMore = false;
        },
        async nextPage(value){
            this.current_page = value;
            this.isFetchingMore = true;
            await this.getData();
        }
    },
    async created(){
        this.isLoading = true;
        await this.getData().then(()=>{
            $('html, body').animate({
                scrollTop: $("#main").offset().top - 100
            }, 20);
        });
    },
    mounted(){
        
    }
}
</script>

<style>

</style>