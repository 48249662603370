<template>
  <!-- ======= Clients Section ======= -->
  <section id="clients" class="clients">

<div class="container" data-aos="fade-up">

  <header class="section-header">
    <h2>Our Partners</h2>
    <!-- <p>Temporibus omnis officia</p> -->
  </header>

  <div class="clients-slider swiper">
    <div class="swiper-wrapper align-items-center">
      <div 
        v-for="data in partners"
        :key="data.id"
        class="swiper-slide">
        <a :href="`${data.link != '' || data.link != null ? data.link : '#'}`" :target="`${data.link != '' || data.link != null ? '_blank' : '_self'}`">
          <img :src="resource + data.photo" class="img-fluid" alt="">
        </a>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</div>

</section><!-- End Clients Section -->
</template>

<script>
import {resource} from '@/networks/domain'
export default {
  props : {
    partners : {
      type : [Object, Array],
      default : []
    }
  },
  data(){
    return {
      resource : resource
    }
  }
}
</script>

<style>

</style>