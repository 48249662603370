<template>
    <div class="col-lg-8 entries">
      <div class="entry rounded">
          <div class="mb-5">
            <h2 class="fw-bold text-center txt-system m-0">Reset Your Password</h2>
          </div>
          <form @submit.prevent="reSetPassword()">
            <div class="row">
                <div class="col-12">
                    <div class="my-3">
                        <label for="password" class="form-group mb-2">New Password</label>
                        <input v-model="password" type="password" class="form-control" required>
                    </div>
                </div>
                <div class="col-12">
                    <div class="my-3">
                        <label for="password" class="form-group mb-2">Confirm Password</label>
                        <input v-model="password_confirmation" type="password" class="form-control" required>
                    </div>
                </div>
            </div>
            <div>
                <button :disabled="isResent" type="button" @click="resend()" class="border-0 bg-tranparent bg-white p-0 txt-system"><i class="bi bi-arrow-repeat"></i>{{ isResent ? 'Resending...' : "Resend" }}  ?</button>
            </div>
            <div class="text-end mt-3">
                <button type="submit" class="btn btn-outline-primary">
                    <h4 class="m-0">Save & Change</h4>
                </button>
            </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
      data(){
          return{
              isLoading : false,
              isResent : false,
              password_confirmation : '',
              password : ''
          }
      },
      methods : {
        async resend(){
            this.isResent = true;
            await axios.post('/member/re-send-reset-password-link',{
                token : this.$route.params.token
            }).then((res)=>{
                if(res.status == 200){
                    this.$swal(
                        {
                            icon: 'success',
                            title : `${res.data.sms}`
                        }
                    );
                } else {
                    this.$swal(
                        {
                            icon: 'error',
                            title : `${res.data.sms}`
                        }
                    );
                }
            }).catch((err)=>{
                this.$swal(
                        {
                            icon: 'warning',
                            title: err.message,
                        }
                    );
            })
            this.isResent = false;
        },
        async reSetPassword(){
            const self = this;
            await axios.post('/member/resetpassword',{
                password : this.password,
                password_confirmation : this.password_confirmation,
                token : this.$route.params.token
            }).then((res)=>{
                if(res.status == 200){
                    this.$swal(
                        {
                            icon: 'success',
                            title : `${res.data.sms}`
                        }
                    ).then((result) => {
                        if (result.isConfirmed) {
                            self.$router.push('/login');
                        }
                    });
                } else {
                    this.$swal(
                        {
                            icon: 'error',
                            title : `${res.data.sms}`
                        }
                    );
                }
            }).catch((err)=>{
                this.$swal(
                    {
                        icon: 'warning',
                        title: err.message,
                    }
                )
            })
        },
      },
      mounted(){
          $('html, body').animate({
              scrollTop: $("#main").offset().top - 100
          }, 20);
      }
  }
  </script>
  
  <style>
  
  </style>