<template>
  <!-- ======= Team Section ======= -->
  <section id="team" class="team">

<div class="container" data-aos="fade-up" v-if="boards.length > 0">

  <header class="section-header">
    <h2>BOARD</h2>
    <p>Our board of director</p>
  </header>

  <div class="row gy-4">

    <div 
      v-for="(data, index) in boards"
      :key="data.id"
      class="col-lg-3 col-md-6 d-flex align-items-stretch" 
      data-aos="fade-up" 
      :data-aos-delay="(index+1) * 100"
    >
      <div class="member">
        <div class="member-img">
          <img :src="resource + data.photo" class="img-fluid" alt="">
          <!-- <div class="social">
            <a href=""><i class="bi bi-email"></i> {{ data.email }}</a>
            <a href=""><i class="bi bi-phone"></i> {{ data.phone }}</a>
            <a href=""><i class="bi bi-instagram"></i></a>
            <a href=""><i class="bi bi-linkedin"></i></a>
          </div> -->
        </div>
        <div class="member-info">
          <h4>{{ data.full_name }}</h4>
          <span>{{ data.position }}</span>
          <p>{{ data.description }}</p>
        </div>
      </div>
    </div>

  </div>

</div>

</section><!-- End Team Section -->
</template>

<script>
import {resource} from '@/networks/domain'
export default {
  props : {
    boards : {
      type : [Object, Array],
      default : []
    }
  },
  data(){
    return {
      resource : resource
    }
  }
}
</script>

<style>

</style>