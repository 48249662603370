<template>
  <header id="header" class="header fixed-top">
    <div v-if="isTrue" class="container-fluid container-xl d-flex align-items-center justify-content-between">

      <router-link to="/" class="logo d-flex align-items-center">
        <img :src="resource + data.logo.header_logo" alt="" >
      </router-link>

      <nav id="navbar" class="navbar">
        <ul v-if="$route.name == 'home'">
          <li><a href="#home" class="nav-link scrollto active">Home </a></li>
          <li><a class="nav-link scrollto" href="#about">About</a></li>
          <li class="dropdown" @click.prevent="clickMobileBoard($event.target)">
            <a href="#">
              <span>Board</span> 
              <i class="bi bi-chevron-down me-2"></i>
            </a>
            <ul id="dropNavBoard">
              <li><router-link to="/board">Board of Directors</router-link></li>
              <li><router-link :to="{ name : 'organization-structure-chart', params :{ data : $CryptoJS.AES.encrypt(`${organizationchart.photo + '-----' + organizationchart.description_en + '-----' + organizationchart.name_en}`, 'Secret Passphrase').toString() } }" :class="`${$route.name == 'organization-structure-chart' ? 'active' : ''}`">Organizational Chart</router-link></li>
            </ul>
          </li>
          <li><a class="nav-link scrollto" href="#portfolio">Gallery</a></li>
          <li><a class="nav-link scrollto" href="#recent-blog-posts">News</a></li>
          <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
          <!-- <li v-if="!$store.state.auth.token"><router-link to="/register" class="getstarted scrollto">Register Now</router-link></li> -->
          <li v-if="!$store.state.auth.token" class="dropdown" @click.prevent="clickMobile($event.target)">
            <a href="#" :class="`${$route.name != 'register' && $route.name != 'membership-benefit' ? '' : 'active'}`">
              <span>Member</span> 
              <i class="bi bi-chevron-down me-2"></i>
            </a>
            <ul id="dropNav">
              <li>
                <router-link :class="`${$route.name == 'membership-benefit' ? 'active' : ''}`"  :to="{ name : 'membership-benefit', params :{ data : $CryptoJS.AES.encrypt(`${membershipbenifits.description_en + '-----' + membershipbenifits.photo + '-----' + membershipbenifits.name_en} `, 'Secret Passphrase').toString() } }" >{{ membershipbenifits.name_en }}</router-link>
              </li>
              <li><router-link to="/register" :class="`${$route.name == 'register' ? 'active' : ''}`">Register Now</router-link></li>
            </ul>
          </li>
          <li v-if="!$store.state.auth.token"><router-link to="/login" class="getstarted scrollto">Login</router-link></li>
          <li v-if="$store.state.auth.token" class="dropdown" @click.prevent="clickMobile($event.target)">
            <a href="#" :class="`${$route.name != 'admin-company' && $route.name != 'admin-document' && $route.name != 'admin-membership-profile' ? '' && $route.name != 'admin-credential' : 'active'}`">
              <span>My Profile</span> 
              <i class="bi bi-chevron-down me-2"></i>
              <img v-if="isLoading" src="@/assets/sniper.jpeg" width="41" alt="">
              <img v-else-if="$store.state.auth.photo != 'default.png'" :src="resource + $store.state.auth.photo" width="41" height="41" class="rounded-circle" style="outline: solid #4154f1; outline-offset: 2px; -webkit-border-radius: 50%; -moz-border-radius: 50%;" alt="">
              <img v-else src="@/assets/avatar.png" width="41" class="img-fluid img-thumbnail rounded-circle" alt="">
            </a>
            <ul id="dropNav">
              <!-- <li><router-link to="/admin/company" :class="`${$route.name != 'admin-company' && $route.name != 'admin-document' && $route.name != 'admin-membership-profile' ? '' : 'active'}`">Dashboard</router-link></li> -->
              <li>
                <router-link v-if="$store.state.auth.isApproved == 1" to="/admin/company" :class="` ${$route.path == '/admin/company' ? 'active' : ''}`">Company Directories</router-link>
              </li>
              <li>
                <router-link v-if="$store.state.auth.isApproved == 1" to="/admin/document" :class="` ${$route.path == '/admin/document' ? 'active' : ''}`">Useful Document</router-link>
              </li>
              <li>
                <router-link to="/admin/membership-profile" :class="` ${$route.path == '/admin/membership-profile' ? 'active' : ''}`">Membership Profile</router-link>
              </li>
              <li>
                <router-link to="/admin/credential" :class="` ${$route.path == '/admin/credential' ? 'active' : ''}`">Credential</router-link>
              </li>
              <li><a href="#" @click="logout()">
                <span v-if="isLoading" class="text-muted">Loading ...</span><span v-else>Logout</span>
              </a></li>
            </ul>
          </li>
        </ul>
        <ul v-else>
          <li><router-link :to="{ name: 'home', params: { whereIs: 'home' }}" class="nav-link scrollto">Home </router-link></li>
          <li><router-link :to="{ name: 'home', params: { whereIs: 'about' }}" class="nav-link scrollto">About</router-link></li>
          <li class="dropdown" @click.prevent="clickMobileBoard($event.target)">
            <a href="#" :class="`${$route.name == 'organization-structure-chart' ? 'active' : ''}`">
              <span>Board</span> 
              <i class="bi bi-chevron-down me-2"></i>
            </a>
            <ul id="dropNavBoard">
              <!-- <li><router-link :to="{ name: 'home', params: { whereIs: 'team' }}" class="nav-link scrollto">Board Director</router-link></li> -->
              <li><router-link to="/board">Board of Directors</router-link></li>
              <li><router-link :to="{ name : 'organization-structure-chart', params :{ data : $CryptoJS.AES.encrypt(`${organizationchart.photo + '-----' + organizationchart.description_en + '-----' + organizationchart.name_en}`, 'Secret Passphrase').toString() } }" :class="`${$route.name == 'organization-structure-chart' ? 'active' : ''}`">Organizational Chart</router-link></li>
            </ul>
          </li>
          <li><router-link :to="{ name: 'home', params: { whereIs: 'portfolio' }}" class="nav-link scrollto">Gallery</router-link></li>
          <li><router-link to="/news" :class="`nav-link scrollto ${ $route.name == 'news' || $route.name == 'news-detail' ? 'active' : '' }`">News</router-link></li>
          <li><router-link :to="{ name: 'home', params: { whereIs: 'contact' }}" class="nav-link scrollto">Contact</router-link></li>
          <!-- <li v-if="!$store.state.auth.token"><router-link to="/register" class="getstarted scrollto">Register Now</router-link></li> -->
          <li v-if="!$store.state.auth.token" class="dropdown" @click.prevent="clickMobile($event.target)">
            <a href="#" :class="`${$route.name != 'register' && $route.name != 'membership-benefit' ? '' : 'active'}`">
              <span>Member</span> 
              <i class="bi bi-chevron-down me-2"></i>
            </a>
            <ul id="dropNav">
              <router-link :class="`${$route.name == 'membership-benefit' ? 'active' : ''}`" :to="{ name : 'membership-benefit', params :{ data : $CryptoJS.AES.encrypt(`${membershipbenifits.description_en + '-----' + membershipbenifits.photo + '-----' + membershipbenifits.name_en} `, 'Secret Passphrase').toString() } }">{{ membershipbenifits.name_en }}</router-link>
              <li><router-link to="/register" :class="`${$route.name == 'register' ? 'active' : ''}`">Register Now</router-link></li>
            </ul>
          </li>
          <li v-if="!$store.state.auth.token"><router-link to="/login" class="getstarted scrollto">Login</router-link></li>
          <li v-if="$store.state.auth.token" class="dropdown" @click.prevent="clickMobile($event.target)">
            <a href="#" :class="`${$route.name != 'admin-company' && $route.name != 'admin-document' && $route.name != 'admin-membership-profile' && $route.name != 'admin-credential' ? '' : 'active'}`">
              <span>My Profile</span> 
              <i class="bi bi-chevron-down me-2"></i>
              <img v-if="isLoading" src="@/assets/sniper.jpeg" width="41" alt="">
              <img v-else-if="$store.state.auth.photo != 'default.png'" :src="resource + $store.state.auth.photo" width="41" height="41" class="rounded-circle" style="outline: solid #4154f1; outline-offset: 2px ; -webkit-border-radius: 50%; -moz-border-radius: 50%;" alt="">
              <img v-else src="@/assets/avatar.png" width="41" class="img-fluid img-thumbnail rounded-circle" alt="">
            </a>
            <ul id="dropNav">
              <!-- <li><router-link to="/admin/company" :class="`${$route.name != 'admin-company' && $route.name != 'admin-document' && $route.name != 'admin-membership-profile' ? '' : 'active'}`">Dashboard</router-link></li> -->
              <li>
                <router-link to="/admin/company" :class="`${$route.path == '/admin/company' ? 'active' : ''}`">Company Directories</router-link>
              </li>
              <li>
                <router-link to="/admin/document" :class="`${$route.path == '/admin/document' ? 'active' : ''}`">Useful Document</router-link>
              </li>
              <li>
                <router-link to="/admin/membership-profile" :class="`${$route.path == '/admin/membership-profile' ? 'active' : ''}`">Membership Profile</router-link>
              </li>
              <li>
                <router-link to="/admin/credential" :class="` ${$route.path == '/admin/credential' ? 'active' : ''}`">Credential</router-link>
              </li>
              <li><a href="#" @click="logout()">
                <span v-if="isLoading" class="text-muted">Loading ...</span><span v-else>Logout</span>
              </a></li>
            </ul>
          </li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle" @click="clickNav($event.target)"></i>
      </nav>
    </div>
  </header>
  <router-view/>
  <footer v-if="isTrue" id="footer" class="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row gy-4">
          <div class="col-lg-6 col-md-12 footer-info">
            <router-link to="/" class="logo d-flex align-items-center">
              <img :src="resource + data.logo.footer_logo" alt="">
              <!-- <span>CBC Cambodia</span> -->
            </router-link>
            <p>{{ data.contact.contacts.short_description }}</p>
            <div class="social-links mt-3">
              <a 
                v-for="item in data.social"
                :key="item.id"
                :href="item.link" 
                target="_blank"
                class="twitter"
              >
                <img :src="resource + item.logo" alt="" class="rounded" width="16">
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-12 footer-links text-center text-md-start">
            <h4>Useful Links</h4>
            <ul v-if="$route.name == 'home'">
              <li><a class=" nav-link scrollto" href="#"><i class="bi bi-chevron-right text-muted"></i> Home</a></li>
              <li><a class=" nav-link scrollto" href="#about"><i class="bi bi-chevron-right text-muted"></i> About us</a></li>
              <li><router-link class=" nav-link scrollto" to="/board"><i class="bi bi-chevron-right text-muted"></i> Board of Directors</router-link></li>
              <li><a class=" nav-link scrollto" href="#portfolio"><i class="bi bi-chevron-right text-muted"></i> Gallery</a></li>
              <li><a class=" nav-link scrollto" href="#recent-blog-posts"><i class="bi bi-chevron-right text-muted"></i> News</a></li>
              <li><router-link class=" nav-link scrollto" :to="{ name : 'term-condition', params :{ data : $CryptoJS.AES.encrypt(`${data.termsandconditions.description_en}`, 'Secret Passphrase').toString() } }"><i class="bi bi-chevron-right text-muted"></i> {{ data.termsandconditions.name_en }}</router-link></li>
            </ul>
            <ul v-else>
              <li><router-link :to="{ name: 'home', params: { whereIs: 'home' }}" class=" nav-link scrollto"><i class="bi bi-chevron-right text-muted"></i> Home </router-link></li>
              <li><router-link :to="{ name: 'home', params: { whereIs: 'about' }}" class=" nav-link scrollto"><i class="bi bi-chevron-right text-muted"></i> About</router-link></li>
              <li><router-link class=" nav-link scrollto" to="/board"><i class="bi bi-chevron-right text-muted"></i> Board of Directors</router-link></li>
              <li><router-link :to="{ name: 'home', params: { whereIs: 'portfolio' }}" class=" nav-link scrollto"><i class="bi bi-chevron-right text-muted"></i> Gallery</router-link></li>
              <li><router-link to="/news" :class="`nav-link scrollto ${ $route.name == 'news' || $route.name == 'news-detail' ? 'active' : '' }`"><i class="bi bi-chevron-right text-muted"></i> News</router-link></li>
              <li><router-link class=" nav-link scrollto" :to="{ name : 'term-condition', params :{ data : $CryptoJS.AES.encrypt(`${data.termsandconditions.description_en}`, 'Secret Passphrase').toString() } }"><i class="bi bi-chevron-right text-muted"></i> {{ data.termsandconditions.name_en }}</router-link></li>
            </ul>
          </div>

          <!-- <div class="col-lg-2 col-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i class="bi bi-chevron-right"></i> <a href="#">Web Design</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">Web Development</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">Product Management</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">Marketing</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">Graphic Design</a></li>
            </ul>
          </div> -->

          <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
            <h4>Contact Us</h4>
            <p>
              {{ data.contact.contacts.address }}
              <br><br>
              <strong>Phone :</strong> {{ data.contact.contacts.phone_1 }} <br>
              <strong class="invisible">Phone</strong> <strong>&nbsp;</strong> {{ data.contact.contacts.phone_2 }} <br>
              <strong>Email:</strong> {{ data.contact.contacts.email }}<br>
            </p>

          </div>

        </div>
      </div>
    </div>
    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>CBC Cambodia</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
        <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/CBC Cambodia-bootstrap-startup-template/ -->
        <!-- Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> -->
      </div>
    </div>
  </footer>
  <a href="#" @click.prevent="goToTop()" id="btnUp" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
</template>

<script>
import { loadJs } from './everythingJS/until';
import axios from 'axios';
import { getContact } from '@/networks/HomePage/routing.service';
import { resource } from '@/networks/domain';
import { logout } from '@/networks/Auth/login.service'
export default {
  data(){
    return {
      data : [],
      resource : resource,
      isTrue : false,
      isLoading : false,
      membershipbenifits : {},
      organizationchart : {},
      desc : '',
    }
  },
  methods: {
    goToTop(){
      $('html, body').animate({
        scrollTop: $("#main").offset().top - 100
      }, 20);
    },
    async logout(){
      if(this.isLoading == true) return ;
      this.isLoading = true;
      await axios.post(logout)
      .then((res)=>{
        if(res.data.status == "success"){
          localStorage.clear();
          this.$store.commit('id', '');
          this.$store.commit('username', '');
          this.$store.commit('email', '');
          this.$store.commit('language', '');
          this.$store.commit('member_id', '');
          this.$store.commit('role_id', '');
          this.$store.commit('photo', '');
          this.$store.commit('phone', '');
          this.$store.commit('token', '');
          this.$router.push("/login");
          this.$swal(
                      {
                          position: 'top-center',
                          icon: 'success',
                          title: res.data.sms,
                          showConfirmButton: false,
                          timer: 1200
                        }
                    );
        } else {
          this.$swal(
                      {
                        icon: 'error',
                        title : `${res.data.sms}`
                      }
                    );
        }
      })
      .catch((err)=>{
        console.log(err);
      })
      this.isLoading = false;
    },
    imageExist(url) 
    {
      var img = new Image();
      img.src = url;
      return img.height != 0;
    },
    clickNav(e){
      $('#navbar').toggleClass('navbar-mobile')
      e.classList.toggle('bi-list')
      e.classList.toggle('bi-x')
    },
    clickMobile(e){
      if($('.mobile-nav-toggle ').hasClass('bi-x')){
        $('#dropNav').toggleClass('dropdown-active');
      }
    },
    clickMobileBoard(e){
      if($('.mobile-nav-toggle ').hasClass('bi-x')){
        $('#dropNavBoard').toggleClass('dropdown-active');
      }
    },
    async getData(){
      await axios.get(getContact)
        .then((res) => {
          if(res.status == 200){
            this.data = res.data.data;
            this.membershipbenifits = res.data.data.membershipbenifits;
            this.organizationchart = res.data.data.organizationchart;
            this.desc = res.data.data.contact.contacts.description;
            this.isTrue = true;
            // $('#icon').attr('href', resource + this.data.logo.login_logo);
          }
        })
        .catch((err)=>{
          console.log(err);
          this.$swal(
                    {
                    icon: 'warning',
                    title: err.message,
                    }
                )
        })
    },
  },
  async created(){
    await this.getData();
  },
  mounted(){
    try{
      loadJs();
      // When the user clicks anywhere outside of the modal, close it
      var modal = document.getElementById("myModal");
      window.onclick = function(event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
      }
    } catch(e){
      console.log(e);
    }
  },
  updated(){
    $('#cbcDesc').attr('content', this.desc);
  }
}
</script>

<style>
extarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input[type="checkbox"]:focus,
input[type="radio"]:focus,
textarea:focus,
.uneditable-input:focus {   
  border-color: #4154f1;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #4154f1;
  outline: 0 none;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #4154f1 !important;
    color: white;
}
</style>