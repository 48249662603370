<template>
    <div v-if="isFetching" class="row my-5">
        <div class="container position-relative p-0 mb-5">
        <div class="carousel-inner animated-background position-relative rounded">
            <div class="carousel-item btn-divide-left active">
                <div class="carousel-caption d-none d-md-block"></div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="row my-5">
        <div class="col-lg-12 col-md-12">
            <h2 class="fw-bolder">Company Detail</h2>
        </div>
        <div class="col-12 shadow rounded-4 mt-3">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="row border-end">
                        <div class="col-lg-6 col-md-12 border-end p-4">
                            <div class="d-flex w-100 h-100 justify-content-center align-items-center">
                                <img v-if="data.logo == '' || data.logo == null" src="@/assets/avatar.png" class="img-fluid img-thumbnail rounded-circle" alt="">
                                <img v-else :src="resource + data.logo" alt="" class="rounded-circle" style="width: 200px; height : 200px; outline: solid #4154f1; outline-offset: 5px ;">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 p-4 d-flex justify-content-lg-center justify-content-start align-items-center">
                            <div>
                                <h2 class="fw-bolder">{{ data.name }}</h2>
                                <p class="my-2"> <i><small>ID : {{ data.code }}</small></i> </p>
                                <p><i class="bi bi-globe"></i> Country : <i> {{ data.country }} </i></p>
                                <p><i class="bi bi-x-diamond"></i> City / State : <i> {{ data.city }} </i></p>
                                <p><i class="bi bi-x-diamond"></i> Zip Code : <i> {{ data.zip_code }} </i></p>
                                <p><i class="bi bi-telephone"></i> Tel : <i>{{ data.phone }}</i></p>
                                <p><i class="bi bi-envelope"></i> Email : <i>{{ data.email }}</i></p>
                                <p><i class="bi bi-browser-chrome"></i> Website : <i>{{ data.website }}</i></p>
                                <p><i class="bi bi-geo-alt"></i> Address : <i>{{ data.address }}</i></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="row">
                        <div class="col-12 p-4 d-flex justify-content-lg-center justify-content-start align-items-center">
                            <div>
                                <h2 class="fw-bolder">Contact Person</h2>
                                <p><i class="bi bi-person"></i> Name : <b>{{ data.key_person_name }}</b></p>
                                <p><i class="bi bi-person-video2"></i> Position : <i>{{ data.key_person_position }}</i></p>
                                <p><i class="bi bi-telephone"></i> Tel : <i>{{ data.key_person_phone }}</i></p>
                                <p><i class="bi bi-envelope"></i> Email : <i>{{ data.key_person_email }}</i></p>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
            <div class="row">
                <div v-if="data.about || data.looking_for" class="col-12 d-flex justify-content-start align-content-center border-top">
                    <div class="py-4 px-2">
                        <h2 v-if="data.about" class="fw-bolder">Brief Description</h2>
                        <p v-if="data.about" style="text-align:justify">{{ data.about }}</p>

                        <h2 v-if="data.looking_for" class="fw-bolder">Looking For</h2>
                        <p v-if="data.looking_for" style="text-align:justify">{{ data.looking_for }}</p>
                    </div>
                </div>
                <div v-if="data.youtube || data.linkedin || data.instragram || data.facebook" class="col-12 d-flex justify-content-start align-content-center border-top">
                    <div class="py-4 px-2">
                        <h2 class="fw-bolder">Connect with us on</h2>
                        <p><i class="bi bi-youtube"></i> Youtube : <i>{{ data.youtube }}</i></p>
                        <p><i class="bi bi-linkedin"></i> Linkedin : <i>{{ data.linkedin }}</i></p>
                        <p><i class="bi bi-instagram"></i> Instragram : <i>{{ data.instragram }}</i></p>
                        <p><i class="bi bi-facebook"></i> Facebook : <i>{{ data.facebook }}</i></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { resource } from '@/networks/domain';
import { companyDetail } from '@/networks/Admin/company.service'

export default {
    data(){
        return{
            resource : resource,
            data : [],
            isFetching : true,
        }
    },
    methods : {
        async getData(){
            await axios.get(`${companyDetail}?id=${this.$CryptoJS.AES.decrypt(`${this.$route.params.id}`, "Secret Passphrase").toString(this.$CryptoJS.enc.Utf8)}`)
            .then((res)=>{
                if(res.data.status == 200){
                    this.$store.commit('isApproved', res.data.data.is_approve);
                    this.data = res.data.data;
                }
            })
            .catch((err)=>{
                console.log(err);
                // this.$swal(
                //     {
                //     icon: 'warning',
                //     title: err,
                //     }
                // )
            })
            this.isFetching = false;
        }
    },
    async created(){
        await this.getData().then(()=>{
            $('html, body').animate({
                scrollTop: $("#main").offset().top - 100
            }, 20);
        });
    }
}
</script>

<style>

</style>