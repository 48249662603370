<template>
  <!-- ======= Recent Blog Posts Section ======= -->
  <section id="recent-blog-posts" class="recent-blog-posts">
    <div class="container" data-aos="fade-up">

    <header class="section-header">
      <h2>News</h2>
      <p>Recent posts</p>
    </header>

    <div class="row">

      <div class="col-lg-4"
        v-for="data in news"
        :key="data.id"
      >
        <div class="post-box">
          <div class="post-img">
            <img :src="resource + data.thumbnail" class="img-fluid" alt="">
          </div>
          <span class="post-date">{{ new Date(data.created_at).toLocaleDateString("en-AU", { weekday : 'long' , day : 'numeric', 'month' : 'numeric' , 'year' : 'numeric', 'hour' : 'numeric', "minute" : "numeric"  }) }} </span>
          <h3 class="post-title">{{ data.title_en }}</h3>
          <router-link :to="{ name: 'news-detail', params: { id: $CryptoJS.AES.encrypt(`${data.id}`, 'Secret Passphrase').toString() }}" class="readmore stretched-link mt-auto">
            <span>Read More</span><i class="bi bi-arrow-right"></i>
          </router-link>
        </div>
      </div>

    </div>
    <div class="row mt-4">
      <div class="col-12 text-center">
        <router-link to="/news" class="btn btn-system">View ALL</router-link>
      </div>
    </div>

    </div>
  </section>
</template>

<script>
import {resource} from '@/networks/domain'
export default {
  props : {
    news : {
      type : [Object, Array],
      default : []
    }
  },
  data(){
    return {
      resource : resource
    }
  },
  mounted(){

  }
}
</script>

<style>

</style>