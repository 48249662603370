<template>
    <div class="col-lg-8 entries pb-4">
        <div v-if="isLoading" class="container position-relative p-0 mb-5">
            <div class="carousel-inner animated-background position-relative rounded">
                <div class="carousel-item btn-divide-left active">
                <div class="carousel-caption d-none d-md-block"></div>
                </div>
            </div>
        </div>
        <div v-else class="rounded shadow">
            <div v-if="item.thumbnail" class="w-100">
                <img :src="resource + item.thumbnail" alt="" class="w-100 rounded-top">
            </div>
            <h2 class="fw-bold entry-title p-4">{{ item.title_en }}</h2>
            <div class="entry-meta">
                <ul class="d-flex gap-3 text-primary">
                    <li class="d-flex align-items-center"><i class="bi bi-eye me-2"></i> {{ item.viewer }} </li>
                    <li class="d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-event" viewBox="0 0 16 16">
                        <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                        </svg> 
                        &nbsp; {{ new Date(item.created_at).toLocaleDateString("en-AU", { weekday : 'long' , day : 'numeric', 'month' : 'numeric' , 'year' : 'numeric', 'hour' : 'numeric', "minute" : "numeric"  }) }}
                    </li>
                </ul>
            </div>
            <div class="entry-content p-4">
                <div class="text-justify" v-html="item.description_en"></div>
            </div>
            <template v-if="photos.length > 0">
                <div v-for="(photo, index) in photos" :key="photo.id" class="w-100 mb-2">
                    <img :src="resource + photo.photo" alt="" :class="`w-100 ${index == photos.length - 1 ? 'rounded-bottom' : ''}`">
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { resource } from '@/networks/domain';
import { newDetail } from '@/networks/NewsPage/news.service';

export default {
    data(){
        return {
            resource : resource,
            item : [],
            photos : [],
            isLoading : true,
        }
    },
    watch: {
        async $route(after, before){
            if(this.$CryptoJS.AES.decrypt(`${after.params.id}`, "Secret Passphrase").toString(this.$CryptoJS.enc.Utf8) != this.$CryptoJS.AES.decrypt(`${before.params.id}`, "Secret Passphrase").toString(this.$CryptoJS.enc.Utf8)){
                this.isLoading = true;
                await this.getData();
            }
        }
    },
    methods : {
        async getData(){
            await axios.get(`${newDetail}?id=${this.$CryptoJS.AES.decrypt(`${this.$route.params.id}`, "Secret Passphrase").toString(this.$CryptoJS.enc.Utf8)}`)
            .then((res)=>{
                if(res.status == 200){
                    this.item = res.data.data;
                    this.photos = res.data.data.photos;
                }
            })
            .then(()=>{
                $('html, body').animate({
                    scrollTop: $("#main").offset().top - 100
                }, 20);
            })
            .catch((err)=>{
                console.log(err);
                // this.$swal(
                //     {
                //         icon: 'warning',
                //         title: err,
                //     }
                // )
            });
            this.isLoading = false;
        }
    },
    async created(){
        await this.getData();
    },
}
</script>

<style>

</style>