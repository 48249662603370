<template>
  <!-- ======= Counts Section ======= -->
  <section id="counts" class="counts" style="margin-top: -5px;">
      <div class="container" data-aos="fade-up">
        <div class="row gy-4">
          <div class="col-lg-3 col-md-6" v-for="(data,index) in counters" :key="data.id">
            <div class="count-box" @click="goTo(index)">
              <i :class="data.icon"></i>
              <div>
                <span data-purecounter-start="0" :data-purecounter-end="`${data.count}`" data-purecounter-duration="1" class="purecounter"></span>
                <p>{{ data.name }}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section><!-- End Counts Section -->
</template>

<script>
import {resource} from '@/networks/domain'
export default {
  props : {
    counters : {
      type : [Object, Array],
      default : []
    }
  },
  data(){
    return {
      resource : resource
    }
  },
  methods : {
    goTo(index){
      if(index == 3){
        // this.$router.push('/event');
      }
    }
  }
}
</script>

<style>

</style>