<template>
  <!-- ======= Values Section ======= -->
    <section id="values" class="values">

        <div class="container" data-aos="fade-up">

        <header class="section-header">
            <h2>Our Mission</h2>
            <p>Cambodia Businesspreneur Council (CBC)</p>
        </header>

        <div class="row">

            <div 
                v-for="(data,index) in missions"
                :key="data.id"
                class="col-lg-4" 
                data-aos="fade-up" 
                :data-aos-delay="(index + 1) * 200"
            >
                <div class="box">
                    <img :src="resource + data.photo" class="img-fluid" alt="">
                    <h3>{{ data.name_en }}</h3>
                    <div v-html="(data.description_en).replace(/<[^>]*>?/gm, '')"></div>
                </div>
            </div>

        </div>

        </div>

    </section><!-- End Values Section -->
</template>

<script>
import {resource} from '@/networks/domain'
export default {
  props : {
    missions : {
      type : [Object, Array],
      default : []
    }
  },
  data(){
    return {
      resource : resource
    }
  }
}
</script>

<style>

</style>