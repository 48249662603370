<template>
  <div class="col-lg-8 entries">
    <div class="entry rounded">
        <div class="mb-5">
            <h2 class="fw-bolder mb-2">Forget Password</h2> 
            <router-link to="/login" class="text-decoration-underline txt-system"><i class="bi bi-arrow-left"></i> Go Back</router-link>
            <p class="text-muted mt-4">Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.</p>
        </div>
        <form
            @submit.prevent="forgetPassword()"
        >
            <div class="mb-3">
                <label for="email" class="form-label fw-bold">Username or email <span class="text-danger">*</span></label>
                <input v-model="email" type="email" class="form-control" id="email" required>
            </div>
            <div class="mt-3 text-end">
                <button type="submit" :disabled="isLoading" class=" btn btn-outline-primary">
                    <span v-if="isLoading">Processing ...</span>
                    <span v-else>Reset password</span>
                </button>
            </div>
        </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    data(){
        return{
            isLoading : false,
            email : '',
        }
    },
    methods : {
        async forgetPassword(){
            this.isLoading = true;
            await axios.post('/member/send-reset-password-link', {
                email : this.email
            }).then((res)=>{
                if(res.status == 200){
                    this.$swal(
                        {
                            icon: 'success',
                            title : `${res.data.sms}`
                        }
                    );
                } else {
                    this.$swal(
                        {
                            icon: 'error',
                            title : `${res.data.sms}`
                        }
                    );
                }
            }).catch((err)=>{
                this.$swal(
                    {
                        icon: 'warning',
                        title: err.message,
                    }
                )
            })
            this.isLoading = false;
        }   
    },
    mounted(){
        $('html, body').animate({
            scrollTop: $("#main").offset().top - 100
        }, 20);
    }
}
</script>

<style>

</style>