<template>
<main id="main">
    <section id="blog" class="blog" style="margin-top:100px;">
        <div class="container" data-aos="fade-up">
            <div class="row">
                <router-view />
                <div class="col-lg-4">
                    <div v-if="isTrue" class="sidebar rounded p-0 m-0 ms-sm-3">
                        <div class="carousel-inner animated-background position-relative rounded">
                            <div class="carousel-item btn-divide-left active">
                                <div class="carousel-caption d-none d-md-block"></div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="sidebar rounded m-0 ms-sm-3">
                        <!-- <h3 class="sidebar-title">Search</h3>
                        <div class="sidebar-item search-form">
                            <form action="">
                                <input type="text">
                                <button type="submit"><i class="bi bi-search"></i></button>
                            </form>
                        </div> -->
                        <section class="p-0" v-for="video in videos" :key="video.id">
                            <div v-if="video.link">
                                <div class="txt-system"> {{ video.name }}</div>
                                <div class="text-muted">
                                    <p id="bridge_subtitle">
                                        {{ video.description }}
                                    </p>
                                    <iframe width="100%" height="200" :src="video.link"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                           
                        </section>
                        <h3 class="sidebar-title">Recent Posts</h3>
                        <div class="sidebar-item recent-posts">
                            <div class="post-item clearfix" v-for="article in articles" :key="article.id">
                                <img :src="resource + article.thumbnail" alt="">
                                <h4><router-link :to="{ name: 'news-detail', params: { id: $CryptoJS.AES.encrypt(`${article.id}`, 'Secret Passphrase').toString() }}" >{{ article.title_en }}</router-link></h4>
                                <time datetime="2020-01-01">{{ new Date(article.created_at).toLocaleDateString("en-AU", { weekday : 'long' , day : 'numeric', 'month' : 'numeric' , 'year' : 'numeric', 'hour' : 'numeric', "minute" : "numeric"  }) }}</time>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
</template>

<script>
import axios from 'axios';
import { sideBar } from '@/networks/NewsPage/news.service'
import { resource } from '@/networks/domain';

export default {
    data(){
        return {
            articles : [],
            videos : [],
            resource : resource,
            isTrue : true
        }
    },
    methods : {
        async getData(){
            await axios.get(sideBar)
            .then((res)=>{
                if(res.status == 200){
                    this.articles = res.data.data.articles;
                    this.videos = res.data.data.vidoes;
                    this.isTrue = false;
                }
            })
            .then(() => {
                $('html, body').animate({
                    scrollTop: $("#main").offset().top - 100
                }, 20);
            })
            .catch((err)=>{
                // console.log(err);
                this.$swal(
                    {
                    icon: 'warning',
                    title: err.message,
                    }
                )
            });
        }
    },
    async created(){
        await this. getData();
    },
    mounted() {
        
    },
    updated(){
        
    }
}
</script>

<style>

</style>
