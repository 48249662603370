<template>
<div class="row my-5">
    <!-- <div class="col-12">
        <h2 class="fw-bolder">Credential</h2>
    </div> -->
    <div class="col-12 shadow rounded-4">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="row border-end">
                        <div class="col-lg-3 col-md-12 border-end p-4">
                            <div class="d-flex w-100 justify-content-center align-items-center position-relative">
                                <div class="position-relative">
                                    <img v-if="phoo" :src="photo" class="rounded-circle" style="width: 200px; height : 200px ; outline: solid #4154f1; outline-offset: 5px ;"/>
                                    <img v-else src="@/assets/avatar.png" class="img-fluid img-thumbnail rounded-circle" alt=""> 
                                    <input v-if="!pho" type="file" @change="changeImg('photo',$event)" id="photo" class="inputFIle position-absolute top-50 translate-middle-y rounded-circle opacity-0" style="width: 30px; z-index: 1; right: -20px;">
                                    <button v-if="pho" @click="resetImg('photo')" title="Reset Photo" class="btnPen position-absolute top-50  translate-middle-y shadow p-2 btn btn-dark border-dark rounded-circle d-flex align-items-center justify-content-center" style="width:30px; height:30px; right: -20px;"><i class="bi bi-arrow-clockwise"></i></button>
                                    <button v-else title="Add Photo" class="btnPen position-absolute top-50 translate-middle-y shadow p-2 btn btn-dark border-dark rounded-circle d-flex align-items-center justify-content-center" style="width:30px; height:30px; right: -20px;"><i class="bi bi-camera"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-12 p-4">
                            <h2 class="fw-bolder">Credential</h2>
                            <form @submit.prevent="updateInfo()">
                                <div class="row my-2">
                                    <div class="col-3 m-auto">
                                        <label for="email">Email :</label>
                                    </div>
                                    <div class="col-9 form-group">
                                        <input v-model="email" id="email" type="email" class="form-control">
                                    </div>
                                </div>
                                <div class="row my-2">
                                    <div class="col-3 m-auto">
                                        <label for="password">Password :</label>
                                    </div>
                                    <div class="col-9 form-group">
                                        <input v-model="password" id="password" type="password" class="form-control">
                                    </div>
                                </div>
                                <div class="row my-2">
                                    <div class="col-3 m-auto">
                                        <label for="password_confirm">Confirm Password :</label>
                                    </div>
                                    <div class="col-9 form-group">
                                        <input v-model="confirmPassword" id="password_confirm" type="password" class="form-control" :required="password != ''">
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-12 text-end">
                                        <button :disabled="isUpdate" type="submit" class="btn btn-outline-primary">
                                            <span v-if="!isUpdate">Update</span>
                                            <span v-else>Updating ...</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>

<script>
import { updateProfile } from '@/networks/Admin/memberProfile.service'
import { resource } from '@/networks/domain';
import axios from 'axios';
export default {
    name : 'CredentialView',
    data(){
        return{
            email : this.$store.state.auth.email,
            password : '',
            confirmPassword : '',
            photo : this.$store.state.auth.photo != 'default.png' ? resource + this.$store.state.auth.photo : '',
            pho: false,
            phoo: this.$store.state.auth.photo != 'default.png' ? resource + this.$store.state.auth.photo : '',
            isUpdate : false,
        }
    },
    methods : {
        resetImg(value){
            if(value == "photo"){
                this.photo = resource + this.$store.state.auth.photo;
                this.pho = false;
                this.phoo = resource + this.$store.state.auth.photo;
                $('#photo').val('');
            }
        },
        changeImg(value,obj){
            if(value == "photo"){
                this.photo = URL.createObjectURL(obj.target.files[0]);
                this.phoo = obj.target.files[0];
                this.pho = true;
            }
        },
        async updateInfo(){
            this.isUpdate = true;

            var data = new FormData();
            data.append('photo',this.phoo);
            data.append('email',this.email);
            data.append('password',this.password);
            data.append('password_confirmation',this.confirmPassword);
            data.append('id',this.$store.state.auth.id);


            await axios.post(updateProfile, data)
            .then((res)=>{
                if(res.data.status == 200){
                    this.$swal(
                        {
                            position: 'top-center',
                            icon: 'success',
                            title: res.data.sms,
                            showConfirmButton: false,
                            timer: 1200
                        }
                    );
                    this.password = '';
                    this.confirmPassword = '';

                    localStorage.email = res.data.data.email;
                    localStorage.photo = res.data.data.photo; 
                    this.$store.commit('id', res.data.data.id);
                    this.$store.commit('photo', res.data.data.photo);

                    this.photo = resource + this.$store.state.auth.photo;
                    this.pho = false;
                    this.phoo = resource + this.$store.state.auth.photo;

                } else{
                    this.$swal(
                        {
                            icon: 'error',
                            title : `${res.data.sms}`
                        }
                    );
                }
            }).catch((err)=>{
                console.log(err);
                // this.$swal(
                //     {
                //         icon: 'warning',
                //         title: err,
                //     }
                // )
            })
            this.isUpdate = false;
        },
    },
    created(){
        $('html, body').animate({
            scrollTop: $("#main").offset().top - 100
        });
    }
}
</script>

<style scoped>
.inputFIle:hover ~ .btnPen{
    background-color: #000000;
    color: #ffffff;
}
</style>