export {
    getProfile,
    getDataTable,
    saveDocument,
    getDocumentById,
    updateProfile,
    updateBriefandLookingFor
}

const getProfile = '/member/profile';
const updateBriefandLookingFor = '/member/profile/update';
const updateProfile = '/member/account-update';
const getDataTable = '/member/document';
const saveDocument = '/member/document/save';
const getDocumentById = '/member/document/getedit'