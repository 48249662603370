<template>
  <div id="create" class="modal">
    <!-- Modal content -->
    <div class="modal-content">
        <div class="d-flex justify-content-between align-items-center">
            <h2 class="fw-bolder">Add Document</h2>
            <i class="bi bi-x-circle close" @click="closeModal()"></i>
        </div>
        <form action="" @submit.prevent="submit()">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="mb-3">
                                <label for="description" class="form-label">Description</label>
                                <input v-model="description"  type="text" class="form-control" id="description" placeholder="Description" required>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="mb-3">
                                <label for="documentType" class="form-label">Select Type</label>
                                <Select2 
                                    id="documentType"
                                    v-model="documentTypeId" 
                                    :options="documentTypes" 
                                    @change="myChangeEvent($event)"
                                    @select="mySelectEvent($event)"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label for="photo" class="form-label">Photo</label>
                        <input id="photo" @change="changeImg('photo',$event)" type="file" class="form-control" required>
                        <button type="button" @click="resetImg('photo')" v-if="pho" title="Reset Photo" class="btn btn-outline-dark float-end mt-3"><i class="bi bi-arrow-clockwise"></i></button>
                        <img v-if="phoo" :src="photo" class="my-2 w-25 rounded"/>
                    </div>
                </div>

                <div class="col-12 text-end border-top pt-3 mt-1">
                    <button :disabled="isAdd" type="submit" class="btn btn-outline-primary">
                        <span v-if="!isAdd">Submit</span>
                        <span v-else>Processing ...</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import Select2 from '@/components/select2/Select2View.vue';
import { saveDocument } from '@/networks/Admin/memberProfile.service'
import axios from 'axios';
 
export default {
    props : {
        documentTypes : {
            type : [Object, Array],
            default : []
        }
    },
    components : {
        Select2
    },
    data(){
        return {
            documentTypeId : '',
            photo : '',
            pho: false,
            phoo: null,
            description : '',
            isAdd : false
        }
    },
    methods : {
        myChangeEvent(e){
            // console.log(e);
        },
        mySelectEvent(e){
            // console.log(e);
        },
        closeModal(){
            // When the user clicks on <span> (x), close the modal
            $('#create').toggleClass('d-block');
            this.clearData();
        },
        clearData(){
            this.documentTypeId = '',
            this.photo = '',
            this.pho= false,
            this.phoo= null,
            this.description = '',
            this.isAdd = false
            $('#photo').val('');
        },
        resetImg(value){
            if(value == "photo"){
                this.photo = '';
                this.pho = false;
                this.phoo = null;
                $('#photo').val('');
            }
        },
        changeImg(value,obj){
            if(value == "photo"){
                this.photo = URL.createObjectURL(obj.target.files[0]);
                this.phoo = obj.target.files[0];
                this.pho = true;
            }
        },
        async submit(){
            this.isAdd = true;
            var data = new FormData();
            data.append('photo',this.phoo);
            data.append('type_id', this.documentTypeId);
            data.append('member_id', this.$store.state.auth.member_id);
            data.append('description',this.description);

            await axios.post(saveDocument, data)
            .then((res) => {
                if(res.data.status == 200){
                    this.$swal(
                        {
                            position: 'top-center',
                            icon: 'success',
                            title: res.data.sms,
                            showConfirmButton: false,
                            timer: 1200
                        }
                    );
                    
                    this.closeModal();
                    this.$emit('AddSuccess');
                } else {
                    this.$swal(
                        {
                            icon: 'error',
                            title : `${res.data.sms}`
                        }
                    );
                }
            })
            .catch((err)=>{
                // this.$swal(
                //     {
                //         icon: 'warning',
                //         title: err,
                //     }
                // )
            })
            this.isAdd = false;
        }
    }
}
</script>

<style>
 #documentType ~ .select2{
    width: 100% !important;
 }
</style>