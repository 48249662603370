<template>
  <!-- ======= Portfolio Section ======= -->
  <section id="portfolio" class="portfolio">

<div class="container" data-aos="fade-up">

  <header class="section-header">
    <h2>Gallery</h2>
    <p>Check our latest work</p>
  </header>

  <!-- <div class="row" data-aos="fade-up" data-aos-delay="100">
    <div class="col-lg-12 d-flex justify-content-center">
      <ul id="portfolio-flters">
        <li data-filter="*" class="filter-active">All</li>
        <li data-filter=".filter-app">App</li>
        <li data-filter=".filter-card">Card</li>
        <li data-filter=".filter-web">Web</li>
      </ul>
    </div>
  </div> -->

  <div class="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">

    <div 
      v-for="data in galleries"
      :key="data.id"
      class="col-lg-4 col-md-6 portfolio-item filter-app">
      <div class="portfolio-wrap">
        <img :src="resource + data.thumbnail" class="img-fluid" alt="">
        <div class="portfolio-info">
          <h4>{{ data.title_en }}</h4>
          <div class="portfolio-links">
            <!-- <a href="@/assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfokio-lightbox" title="App 1"><i class="bi bi-plus"></i></a> -->
            <router-link :to="{ name: 'news-detail', params: { id: $CryptoJS.AES.encrypt(`${data.id}`, 'Secret Passphrase').toString() }}" title="More Details">
              <i class="bi bi-link"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

</section><!-- End Portfolio Section -->
</template>

<script>
import {resource} from '@/networks/domain'
export default {
  props : {
    galleries : {
      type : [Object, Array],
      default : []
    }
  },
  data(){
    return {
      resource : resource
    }
  }
}
</script>

<style>

</style>