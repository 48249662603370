<template>
    <div id="companyInfo" class="modal">
      <!-- Modal content -->
      <div class="modal-content">
          <div class="d-flex justify-content-between align-items-center">
              <h2 class="fw-bolder">Edit Company Infomation</h2>
              <i class="bi bi-x-circle close" @click="closeModal()"></i>
          </div>
          <form action="" @submit.prevent="submit()">
            <div class="row">
                <div class="col-lg-4 my-1 col-md-12">
                    <label for="ecountry" class="form-group">Country</label>
                    <Select2 
                        id="ecountry"
                        v-model="company.country_id" 
                        :options="countries" 
                        @change="myChangeEvent($event)"
                        @select="mySelectEvent($event)"
                    />
                </div>
                <div class="col-lg-4 my-1 col-md-12">
                    <label for="ecity" class="form-group">City / State</label>
                    <input v-model="company.city" id="ecity" type="text" class="form-control">
                </div>
                <div class="col-lg-4 my-1 col-md-12">
                    <label for="ezipcode" class="form-group">Zip Code</label>
                    <input v-model="company.zip_code" id="ezipcode" type="text" class="form-control">
                </div>
                <div class="col-lg-4 my-1 col-md-12">
                    <label for="ename" class="form-group">Name</label>
                    <input v-model="company.name" id="ename" type="text" class="form-control">
                </div>
                <div class="col-lg-4 my-1 col-md-12">
                    <label for="ephone" class="form-group">Phone</label>
                    <input v-model="company.phone" id="ephone" type="text" class="form-control">
                </div>
                <div class="col-lg-4 my-1 col-md-12">
                    <label for="eemail" class="form-group">Email</label>
                    <input v-model="company.email" id="eemail" type="email" class="form-control">
                </div>
                <div class="col-lg-4 my-1 col-md-12">
                    <label for="eaddress" class="form-group">Address</label>
                    <input v-model="company.address" id="eaddress" type="text" class="form-control">
                </div>
                <div class="col-lg-4 my-1 col-md-12">
                    <label for="ewebsit" class="form-group">Website</label>
                    <input v-model="company.website" id="ewebsit" type="text" class="form-control">
                </div>
                <div class="col-lg-4 my-1 col-md-12">
                    <label for="eyoutube" class="form-group">Youtube</label>
                    <input v-model="company.youtube" id="eyoutube" type="text" class="form-control">
                </div>
                <div class="col-lg-4 my-1 col-md-12">
                    <label for="elinkedin" class="form-group">Linkedin</label>
                    <input v-model="company.linkedin" id="elinkedin" type="text" class="form-control">
                </div>
                <div class="col-lg-4 my-1 col-md-12">
                    <label for="einstragram" class="form-group">Instagram</label>
                    <input v-model="company.instragram" id="einstragram" type="text" class="form-control">
                </div>
                <div class="col-lg-4 my-1 col-md-12">
                    <label for="efacebook" class="form-group">Facebook</label>
                    <input v-model="company.facebook" id="efacebook" type="text" class="form-control">
                </div>
                <div class="col-lg-4 my-1 col-md-12">
                    <label for="etwitter" class="form-group">Twitter</label>
                    <input v-model="company.twitter" id="etwitter" type="text" class="form-control">
                </div>
                <div class="col-12 my-2 text-end">
                    <button :disabled="isAdd" type="submit" class="btn btn-outline-primary">
                        <span v-if="!isAdd">Update</span>
                        <span v-else>Updating ...</span>
                    </button>
                </div>
            </div>
          </form>
      </div>
  </div>
  </template>
  
  <script>
  import Select2 from '@/components/select2/Select2View.vue';
  import { updateBriefandLookingFor } from '@/networks/Admin/memberProfile.service'
  import axios from 'axios';
  import { resource } from '@/networks/domain';
   
  export default {
      components : {
          Select2
      },
      props : {
        company : {
            type : [Object, Array],
            default : {}
        },
        countries : {
            type : [Object, Array],
            default : []
        }
      },
      data(){
          return {
              documentTypeId : '',
              photo : '',
              pho: false,
              phoo: null,
              description : '',
              isAdd : false,
              resource : resource,
              ecountry: ''
          }
      },
      methods : {
          myChangeEvent(e){
              // console.log(e);
          },
          mySelectEvent(e){
              // console.log(e);
          },
          closeModal(){
              // When the user clicks on <span> (x), close the modal
              $('#companyInfo').toggleClass('d-block');
              this.clearData();
          },
          clearData(){
              this.documentTypeId = '',
              this.photo = '',
              this.pho= false,
              this.phoo= null,
              this.description = '',
              this.isAdd = false
              $('#photo').val('');
          },
          resetImg(value){
              if(value == "photo"){
                  this.photo = '';
                  this.pho = false;
                  this.phoo = null;
                  $('#photo').val('');
                  $('#imgEdit').attr("src",`${resource + this.edata.photo}`);
              }
          },
          changeImg(value,obj){
              if(value == "photo"){
                  this.photo = URL.createObjectURL(obj.target.files[0]);
                  this.phoo = obj.target.files[0];
                  this.pho = true;
                  $('#imgEdit').attr("src",`${this.photo}`);
              }
          },
          async submit(){
              this.isAdd = true;
              var data = {
                member_id : this.$store.state.auth.member_id,
                name : this.company.name,
                phone : this.company.phone,
                email : this.company.email,
                address : this.company.address,
                website : this.company.website,
                youtube : this.company.youtube,
                linkedin : this.company.linkedin,
                instragram : this.company.instragram,
                facebook : this.company.facebook,
                twitter : this.company.twitter,
                country_id : this.company.country_id,
                city : this.company.city,
                zip_code : this.company.zip_code
              };
        
              this.company.country = this.countries.filter((item) => item.id == this.company.country_id)[0].text;
  
              await axios.post(updateBriefandLookingFor, data)
              .then((res) => {
                  if(res.data.status == 200){
                      this.$swal(
                          {
                              position: 'top-center',
                              icon: 'success',
                              title: res.data.sms,
                              showConfirmButton: false,
                              timer: 1200
                          }
                      );
                      this.closeModal();
                      this.$emit('updateCompanyInfo',this.company);
                  } else {
                      this.$swal(
                          {
                              icon: 'error',
                              title : `${res.data.sms}`
                          }
                      );
                  }
              })
              .catch((err)=>{
                // this.$swal(
                //     {
                //         icon: 'warning',
                //         title: err,
                //     }
                // )
              })
              this.isAdd = false;
          }
      }
  }
  </script>
  
  <style>
   #edocumentType ~ .select2, #ecountry ~ .select2{
      width: 100% !important;
   }
  </style>