<template>
    <div id="about" class="modal">
      <!-- Modal content -->
      <div class="modal-content">
          <div class="d-flex justify-content-between align-items-center">
              <h2 class="fw-bolder">Edit Brief Description</h2>
              <i class="bi bi-x-circle close" @click="closeModal()"></i>
          </div>
          <form action="" @submit.prevent="submit()">
            <textarea cols="30" rows="10" :class="`w-100 my-3 ${ lengthBrief > 150 ? 'border-danger focus-danger' : lengthBrief == 150 ? 'border-primary' : '' }`" v-model="brief"></textarea>
            <p :class="`text-end ${ lengthBrief > 150 ? 'text-danger' : lengthBrief == 150 ? 'text-primary' : '' }`"> Words : {{ lengthBrief }} / 150</p>
            <div class="row">
                <div class="col-12 text-end">
                    <button :disabled="isAdd == true || lengthBrief > 150" type="submit" class="btn btn-outline-primary">
                        <span v-if="!isAdd">Update</span>
                        <span v-else>Updating ...</span>
                    </button>
                </div>
            </div>
          </form>
      </div>
  </div>
  </template>
  
  <script>
  import Select2 from '@/components/select2/Select2View.vue';
  import { updateBriefandLookingFor } from '@/networks/Admin/memberProfile.service'
  import axios from 'axios';
  import { resource } from '@/networks/domain';
   
  export default {
      components : {
          Select2
      },
      props : {
        brief : {
            type : [String, Number],
            default : ''
        }
      },
      data(){
          return {
              documentTypeId : '',
              photo : '',
              pho: false,
              phoo: null,
              description : '',
              isAdd : false,
              resource : resource
          }
      },
      computed : {
        lengthBrief(){
            if(this.brief){
                var lengthB = this.brief.split(" ");
                return lengthB.length;
            } else {
                return 0;
            }
        }
      },
      methods : {
          myChangeEvent(e){
              // console.log(e);
          },
          mySelectEvent(e){
              // console.log(e);
          },
          closeModal(){
              // When the user clicks on <span> (x), close the modal
              $('#about').toggleClass('d-block');
              this.clearData();
          },
          clearData(){
              this.documentTypeId = '',
              this.photo = '',
              this.pho= false,
              this.phoo= null,
              this.description = '',
              this.isAdd = false
              $('#photo').val('');
          },
          resetImg(value){
              if(value == "photo"){
                  this.photo = '';
                  this.pho = false;
                  this.phoo = null;
                  $('#photo').val('');
                  $('#imgEdit').attr("src",`${resource + this.edata.photo}`);
              }
          },
          changeImg(value,obj){
              if(value == "photo"){
                  this.photo = URL.createObjectURL(obj.target.files[0]);
                  this.phoo = obj.target.files[0];
                  this.pho = true;
                  $('#imgEdit').attr("src",`${this.photo}`);
              }
          },
          async submit(){
              if(this.lengthBrief > 150) return;
              this.isAdd = true;
              var data = new FormData();
              data.append('member_id', this.$store.state.auth.member_id);
              data.append('about',this.brief);
  
              await axios.post(updateBriefandLookingFor, data)
              .then((res) => {
                  if(res.data.status == 200){
                      this.$swal(
                          {
                              position: 'top-center',
                              icon: 'success',
                              title: res.data.sms,
                              showConfirmButton: false,
                              timer: 1200
                          }
                      );
                      this.closeModal();
                      this.$emit('updateAbout',this.brief);
                  } else {
                      this.$swal(
                          {
                              icon: 'error',
                              title : `${res.data.sms}`
                          }
                      );
                  }
              })
              .catch((err)=>{
                console.log(err);
                // this.$swal(
                //     {
                //         icon: 'warning',
                //         title: err,
                //     }
                // )
              })
              this.isAdd = false;
          }
      }
  }
  </script>
  
  <style>
   #edocumentType ~ .select2{
      width: 100% !important;
   }
   .focus-danger:focus {
     box-shadow: 1px 1px 1px red;
   }
  </style>